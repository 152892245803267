import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import jwt_decode from "jwt-decode"
import { addUser, addToken } from "../../reducers/user/action"
import axios from "axios"
import Navbar from "../navbar/Navbar"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./addAds.css"
import ProductCategory from "../productCategory/ProductCategory"
import CompanyList from "../company/CompanyList"
import Makes from "../productMakes/Makes"
import Models from "../model/Models"
import CompanyContactList from "../companyContact/CompanyContactList"
import Select from "react-select"
import countryList from "react-select-country-list"
import AppContext from "../../AppContext"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import FileUploader from "../FileUploader"
import Category from "../category/Category"
import UploadFile from "../UploadFile"
import FileUpload from "../FileUpload"

function AddAds(props) {
  const [adsTitle, setAdsTitle] = useState()
  const [country, setCountry] = useState()
  const [price, setPrice] = useState(0)
  const [adsLocation, setadsLocation] = useState()
  const [adsDescription, setadsDescription] = useState()
  const [adsImage, setadsImage] = useState()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [makesOptions, setMakesOptions] = useState()
  const [makesList, setMakesList] = useState()
  const [modelsOptions, setModelsOptions] = useState()
  const [category, setCategory] = useState()
  const [categoryName, setCategoryName] = useState()
  const [contactsOptions, setContactsOptions] = useState()
  const [companyOptions, setCompanyOptions] = useState()
  const [companyList, setCompanyList] = useState()
  const [waferSizeOptions, setWaferSizeOptions] = useState()
  const [selectedWaferSizeValue, setSelectedWaferSizeValue] = useState()
  const [configuration, setConfiguration] = useState()
  const [note, setNote] = useState()
  const [vintage, setVintage] = useState()
  const [serialNumber, setSerialNumber] = useState()

  const [value, setValue] = useState("")
  const options = useMemo(() => countryList().getData(), [])

  const [images, setImages] = useState([])
  const [imagesUrls, setImagesUrls] = useState([])
  const [hideFromFrontEnd, setHideFromFrontEnd] = useState(false)
  const [adsType, setAdsType] = useState()

  const changeHandler = value => {
    setValue(value)
  }
  const token = JSON.parse(localStorage.getItem("token"))

  let file = false
  let urls = []

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  useEffect(() => {
    if (props.wanted) {
      setAdsType(props.wanted ? 1 : 0)
    }
  }, [props.wanted])

  let id = state.user.user.id
  let userToken = state.token
  const companyId = 5
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const title = e => {
    setAdsTitle(e.target.value)
  }
  const itemData = [
    {
      img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      title: "Breakfast"
    },
    {
      img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      title: "Burger"
    },
    {
      img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      title: "Camera"
    },
    {
      img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      title: "Coffee"
    },
    {
      img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      title: "Hats"
    },
    {
      img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      title: "Honey"
    },
    {
      img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      title: "Basketball"
    },
    {
      img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      title: "Fern"
    },
    {
      img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      title: "Mushrooms"
    },
    {
      img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      title: "Tomato basil"
    },
    {
      img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      title: "Sea star"
    },
    {
      img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      title: "Bike"
    }
  ]
  const location = e => {
    setadsLocation(e.target.value)
  }

  const description = e => {
    setadsDescription(e.target.value)
  }

  // const categoryRealEstate = e => {
  //   e.preventDefault()

  //   category = 2
  //   document.querySelector("#categoryTitle").innerHTML = "real estate"
  // }

  // const categoryCar = e => {
  //   e.preventDefault()

  //   category = 3
  //   document.querySelector("#categoryTitle").innerHTML = "car"
  // }

  // const categoryDevices = e => {
  //   e.preventDefault()

  //   category = 4
  //   document.querySelector("#categoryTitle").innerHTML = "devices"
  // }

  const uploadImage = async file => {
    // const files = e.target.files
    const data = new FormData()
    data.append("file", file)
    data.append("upload_preset", "adsimage")
    setLoading(true)

    const res = await axios.post("https://api.cloudinary.com/v1_1/elmelm/image/upload", data).then(res => {
      file = true
      setImage(res.data.secure_url)
      let image_urls = imagesUrls
      setImagesUrls(...imagesUrls, res.data.secure_url)
      setLoading(false)
    })
  }

  const postAds = e => {
    e.preventDefault()
    const data = {
      title: adsTitle,
      country: value.value,
      description: adsDescription,
      image: imagesUrls.join("$"),
      location: adsLocation,
      hideFromFrontEnd: hideFromFrontEnd,
      user: {
        id: id
      },
      category: {
        id: selectedCategoryValue ? selectedCategoryValue.value : 0
      },
      company: {
        id: selectedCompanyValue ? selectedCompanyValue.value : 1
      },
      contact: {
        id: selectedCompanyContactValue ? selectedCompanyContactValue.value : 1
      },
      make: {
        id: selectedMakeValue ? selectedMakeValue.value : 0
      },
      model: {
        id: selectedModelValue ? selectedModelValue.value : 0
      },
      price: price ? price.replace("$", "").replace(",", "") : 0,
      status: "ACTIVE",
      waferSize: selectedWaferSizeValue ? selectedWaferSizeValue.value : "",
      configuration: configuration,
      note: note,
      vintage: vintage,
      serialNumber: serialNumber,
      type: adsType
    }

    axios
      .post(`${AppContext.apiUrl}/api/ads`, data, config)
      .then(response => {
        console.log(response.data)
        navigate("/editAds")
      })
      .catch(error => {
        console.log("error happend: ", error)
        navigate("/login")
      })
  }
  const stateValue = { value: "Ion Implanters", label: "Ion Implanters" }

  const [selectedMakeValue, setSelectedMakeValue] = useState("")

  const handleSelectMakeChange = value => {
    setSelectedMakeValue(value)
    console.log("make is ", value)
  }

  const [selectedModelValue, setSelectedModelValue] = useState("")

  const handleSelectModelChange = value => {
    setSelectedModelValue(value)
    console.log("model is ", value)
  }

  const [selectedCategoryValue, setSelectedCategoryValue] = useState("")

  const handleSelectCategoryChange = value => {
    setSelectedCategoryValue(value)
    console.log("category is ", value)
  }

  const [selectedCompanyValue, setSelectedCompanyValue] = useState("")

  const handleSelectCompanyChange = value => {
    setSelectedCompanyValue(value)
    console.log("company is ", value)
  }

  const [selectedCompanyContactValue, setSelectedCompanyContactValue] = useState("")

  const handleSelectCompanyContactChange = value => {
    setSelectedCompanyContactValue(value)
    console.log("contact is ", value)
  }

  useEffect(async () => {
    let result = await axios(`${AppContext.apiUrl}/api/makes`)

    setMakesList(result.data)

    const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
    setMakesOptions(category)

    //set warfsize
    setWaferSizeOptions([
      { value: '4"', label: '4"' },
      { value: '5"', label: '5"' },
      { value: '6"', label: '6"' },
      { value: '8"', label: '8"' },
      { value: '12"', label: '12"' }
    ])
  }, [])

  useEffect(() => {
    if (selectedMakeValue) {
      makesList.filter(makes => {
        if (makes.id === selectedMakeValue.value) {
          const modelsList = makes.model.map(cat => ({ value: cat.id, label: cat.name }))
          setModelsOptions(modelsList)
        }
      })
    }
  }, [selectedMakeValue])

  useEffect(async () => {
    if (selectedModelValue && selectedModelValue !== 0) {
      let result = await axios(`${AppContext.apiUrl}/api/category/byMakeModel/${selectedMakeValue.value}/${selectedModelValue.value}`)

      //const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
      //console.log("category " + category)
      // if (selectedModelValue.value !== 0) {
      setSelectedCategoryValue({ value: result.data.id, label: result.data.name })
      //setCategoryName(result.data.name)
      // } else {
      // }
    }
  }, [selectedModelValue])

  useEffect(async () => {
    let result = await axios(`${AppContext.apiUrl}/api/company`, config)
    setCompanyList(result.data)
    const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
    setCompanyOptions(category)
  }, [])

  useEffect(() => {
    async function uploadImg(file) {
      const data = new FormData()
      data.append("file", file)
      data.append("upload_preset", "adsimage")
      setLoading(true)

      const res = await axios.post("https://api.cloudinary.com/v1_1/elmelm/image/upload", data).then(res => {
        file = true
        //setImage(res.data.secure_url)
        let image_urls = imagesUrls
        setImagesUrls(url => [...url, res.data.secure_url])
        setLoading(false)
        console.log("------parent image change res.data.secure_url--------------->>>")
        console.log(res.data.secure_url)
      })
    }
    if (images) {
      images.forEach(element => {
        uploadImg(element)
      })
    }
  }, [images])

  useEffect(() => {
    console.log("-----imagesUrls---------------->>>")
    console.log(imagesUrls)
    //update image field
    //setImage(imagesUrls.join("$"))
  }, [imagesUrls])

  useEffect(() => {
    if (selectedCompanyValue) {
      companyList.filter(company => {
        if (company.id === selectedCompanyValue.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.contactName }))
          setContactsOptions(contactList)
        }
      })
    }
  }, [selectedCompanyValue])

  return (
    <div>
      <Navbar />
      <form>
        <div className="ads-from">
          <div>
            <label>{props.wanted ? "Wanted" : "Selling"} Ads title</label>
          </div>
          <input type="text" defaultValue={adsTitle} autoFocus onChange={title} name="title" id="title_id" />
          <div>
            <label>Hide from front end?</label>
          </div>
          <div>
            <div>
              Yes
              <input type="radio" name="hideFromFrontEnd" value="true" onChange={e => setHideFromFrontEnd(true)} checked={hideFromFrontEnd === true} />{" "}
            </div>
            <div>
              No <input type="radio" name="hideFromFrontEnd" value="false" onChange={e => setHideFromFrontEnd(false)} checked={hideFromFrontEnd === false} />
            </div>
          </div>
          <div>{/* Yes <input onChange={setHideFromFrontEnd(true)} type="radio" checked={hideFromFrontEnd} name="hideFromFrontEnd" id="hideYes_id" /> No <input type="radio" checked={hideFromFrontEnd} name="hideFromFrontEnd" id="hide_id" /> */}</div>
          <div>
            <label>
              <span style={{ color: "red" }}>*</span>Makes:
            </label>
          </div>
          <div>
            <Makes options={makesOptions} selectedValue={selectedMakeValue} onSelectChange={handleSelectMakeChange} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}>*</span>Model:
            </label>
          </div>
          {/* stateValue={{ value: "PI 9500", label: "PI 9500" }}  */}
          <div>
            <Models options={modelsOptions} selectedValue={selectedModelValue} onSelectChange={handleSelectModelChange} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}>*</span>Category:
            </label>
          </div>
          {/* {props.wanted ? <Category selectedValue={selectedCategoryValue} onSelectChange={handleSelectCategoryChange} /> : <input type="text" disabled autoFocus value={categoryName} name="category" id="category_id" />} */}
          <Category selectedValue={selectedCategoryValue} onSelectChange={handleSelectCategoryChange} />

          <div>
            <label>Description</label>
          </div>
          <textarea rows="4" value={adsDescription} cols="50" placeholder="" name="descritpion" id="des_id" onChange={description} />
          {/* <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
              Category
            </button>
            <ul className="dropdown-menu">
              <button className="dropdown-item" type="button" onClick={categoryRealEstate}>
                backend
              </button>
              <button className="dropdown-item" type="button" onClick={categoryCar}>
                frontend
              </button>
              <button className="dropdown-item" type="button" onClick={categoryDevices}>
                devices
              </button>
            </ul>
            <h5 id="categoryTitle"></h5>
          </div> */}
          <div>
            <label>
              <span style={{ color: "red" }}>*</span>Company:
            </label>
          </div>
          <div>
            <CompanyList options={companyOptions} selectedValue={selectedCompanyValue} onSelectChange={handleSelectCompanyChange} />
          </div>
          <div>
            <label>
              <span style={{ color: "red" }}>*</span>Company contact person:
            </label>
          </div>
          <div>
            <CompanyContactList options={contactsOptions} selectedValue={selectedCompanyContactValue} onSelectChange={handleSelectCompanyContactChange} />
          </div>
          <div>
            <label>Wafer Size:</label>
          </div>
          <Select options={waferSizeOptions} value={selectedWaferSizeValue} onChange={e => setSelectedWaferSizeValue(e)} />
          <div>
            <label>Configuration</label>
          </div>
          <input type="text" value={configuration} name="configuration" id="configuration_id" placeholder="" onChange={e => setConfiguration(e.target.value)} />
          <div>
            <label>note</label>
          </div>
          <input type="text" value={note} name="note" id="note_id" placeholder="" onChange={e => setNote(e.target.value)} />
          <div>
            <label>vintage</label>
          </div>
          <input type="text" value={vintage} name="vintage" id="vintage_id" placeholder="Put only numbers" onChange={e => setVintage(e.target.value)} />
          <div>
            <label>serial number</label>
          </div>
          <input type="text" value={serialNumber} name="serialNumber" id="serialNumber_id" placeholder="" onChange={e => setSerialNumber(e.target.value)} />

          <div>
            <label>country</label>
          </div>
          <Select options={options} value={value} onChange={changeHandler} />

          <div>
            <label>price:</label>
          </div>
          <input type="text" className="text-in-form" value={price} name="price" id="price_id" placeholder="" onChange={e => setPrice(e.target.value)} />

          {/* <label className="image-upload">Upload image</label> */}
          <div className="text-in-form">
            {/* <FileUploader images={images} setImages={setImages}></FileUploader> */}
            <FileUpload initialImages={imagesUrls} onImagesChange={setImagesUrls} />
          </div>
          {/* <input type="file" className="form-control-file image-upload" onChange={uploadImage} /> */}
          {/* <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
            {itemData.map(item => (
              <ImageListItem key={item.img}>
                <img srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`} src={`${item.img}?w=164&h=164&fit=crop&auto=format`} alt={item.title} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList> */}
          <div>
            <button type="submit" className="btn btn-primary" onClick={postAds}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddAds
