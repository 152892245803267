import React, { useState } from 'react';
import './ListingDetailSlidingImages.css'; // Assuming styles are defined here
import RequestForm from '../raiseRequest/requestForm';

const ListingDetailSlidingImages = ({ imageUrls,listingNumber_, itemId_}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [seen, setSeen] = useState(false)
  const fullDomainWithPort = window.location.protocol + "//" + window.location.host
  const defaultImg = "https://res.cloudinary.com/dmcaw0vsd/image/upload/v1710189142/photo-comming_e9f54l.png"

  const [currentListingNumber,setCurrentListingNumber] = useState()
  const [itemUrl,setItemUrl] = useState()
  function togglePop() {
    setSeen(!seen)
  }
  const contactFromLastPicture =(listingNumber,itemId) => {
    setCurrentListingNumber(listingNumber); // Update the currentListingNumber state
    setItemUrl(fullDomainWithPort.concat("/for-sale/"+ itemId))
    setSeen(!seen); // Assuming 'seen' is your state to control popup visibility
  };
  const goToNext = () => {
    
    setCurrentIndex((prevIndex) =>
      prevIndex >= imageUrls.length ? 0 : prevIndex + 1
    );
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length : prevIndex - 1
    );
  };

  const setActiveImage = (index) => {
    setCurrentIndex(index);
  };

  return (
   <> {imageUrls && (
    <div className="carousel-container">
      <div className="thumbnail-container">
        {imageUrls && imageUrls.map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`Resochi.com thumbnail listing_image ${listingNumber_}_${index + 1}`}
            onClick={() => setActiveImage(index)}
            className={index === currentIndex ? 'thumbnail active' : 'thumbnail'}
          />
        ))}
          {/* <div
            className="thumbnail special"
            onClick={() => setCurrentIndex(imageUrls.length)}
          >
            <button className="special-button">Special</button>
          </div> */}
      </div>
      <div className="image-viewer">
        <button onClick={goToPrev} className="nav-button left">{'<'}</button>
        {imageUrls && currentIndex === imageUrls.length ? (
          <div className="special-view"><button onClick={()=>contactFromLastPicture(listingNumber_,itemId_)}>Contact us and get more details</button></div>
        ) : (
          <img
            src={imageUrls!=null?imageUrls[currentIndex]:''}
            alt={`Resochi.com listing_image ${listingNumber_}_${currentIndex + 1}`}
            className="main-image"
          />
        )}
        <button onClick={goToNext} className="nav-button right">{'>'}</button>
        {imageUrls && currentIndex === imageUrls.length ? (
        <></>
        ):(
            <div className="image-count">{`${currentIndex+1} / ${imageUrls!=""?imageUrls.length:"0"}`}</div>
        )}
      </div>
      
    </div>
    )}
    {seen ? <RequestForm toggle={togglePop} listingNumber={currentListingNumber} adsUrl={itemUrl} /> : null}
    </>
  );
};


export default ListingDetailSlidingImages;
