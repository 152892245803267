import React from 'react';
import Select from 'react-select';
import lan_image from './language-11-16.png'

const LanguageSelector = ({ changeLanguage }) => {
    // Options for the dropdown
    const options = [
        { value: 'ch', label: '简体' },
        { value: 'tw', label: '正體' },
        { value: 'en', label: 'en' },
        { value: 'jp', label: '日本語' }
    ];

    // Function to handle change in selected option
    const handleChange = (selectedOption) => {
        changeLanguage(selectedOption.value);
    };
    const theme = (theme: any) => ({
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 18,
          baseUnit: 0,
        }
      });
      
    return (
        <div style={{width:"50px"}}>
            <div style={{float:"left",width:"20px",marginRight:"-10px"}}><img src={lan_image}/></div>
            <div style={{float:"right",width:"30px"}}><Select
                options={options}
                onChange={handleChange}
                isSearchable={false}
                theme={theme}
                
            /></div>
        </div>
    );
};

export default LanguageSelector;
