import React, { useState } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useTranslation, Trans } from "react-i18next";
import RequestForm from "../raiseRequest/requestForm"


const ListDetailsImages = ({ featureProducts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const [seen, setSeen] = useState(false)
  const fullDomainWithPort = window.location.protocol + "//" + window.location.host
  const defaultImg = "https://res.cloudinary.com/dmcaw0vsd/image/upload/v1710189142/photo-comming_e9f54l.png"

  const numberOfImages = featureProducts?featureProducts.image?featureProducts.image.split("$").length:0:0
  function togglePop() {
    setSeen(!seen)
  }
  // Check if there are enough items to navigate
  const canNavigate = numberOfImages > 3?true:false;

  const handlePrevClick = () => {
    if (canNavigate) {
      // Go to the previous set of cards or loop to the end if at the beginning
      setCurrentIndex((prevIndex) => (prevIndex - 3 + numberOfImages) % numberOfImages);
    }
  };

  const handleNextClick = () => {
    if (canNavigate) {
      // Go to the next set of cards or loop back to the start
      setCurrentIndex((prevIndex) => (prevIndex + 3) % numberOfImages);
    }
  };

  return (
    <div style={{paddingLeft:"10px"}}>
      {/* <h1 style={{backgroundColor:"#ffffff",marginBottom:"20px", fontSize:"2rem"}}>{t("Featured Equipment")}</h1> */}
      <Row className="mb-4" >
      {numberOfImages > 3 && (
        <Col xs={1} style={{width:"10px",marginRight:"-30px",zIndex:"1"}} className="d-flex justify-content-center align-items-center">
          <Button onClick={handlePrevClick} disabled={!canNavigate}>&lt;</Button>
        </Col>
      )}
        {numberOfImages>0 && featureProducts.image.split("$").slice(currentIndex, currentIndex + 3).map((item) => (
          <Col key={featureProducts.id+1} xs={10} md={4}>
            <Card>
                      <Card.Img variant="top" style={{ height: "250px", objectFit: "cover",aspectRatio:"1 / 1" }} src={item ? item : defaultImg} />
                      <Card.Body>
                        
                        <Card.Footer onClick={togglePop} style={{ backgroundColor: "rgb(41 72 121)", color: "white" }}>
                          {t("Contact us for the price")}
                        </Card.Footer>
                        {seen ? <RequestForm toggle={togglePop} listingNumber={featureProducts.listingNumber} adsUrl={fullDomainWithPort + "/for-sale/" + featureProducts.id} /> : null}
                      </Card.Body>
                    </Card>
          </Col>
        ))}
        {numberOfImages===0 &&  (
          <Col key={1} xs={10} md={4}>
            <Card>
                      <Card.Img variant="top" style={{ height: "250px", objectFit: "cover",aspectRatio:"1 / 1" }} src={ defaultImg} />
                      <Card.Body>
                        
                        <Card.Footer onClick={togglePop} style={{ backgroundColor: "rgb(41 72 121)", color: "white" }}>
                          {t("Contact us for the price")}
                        </Card.Footer>
                        {seen ? <RequestForm toggle={togglePop} listingNumber={featureProducts.listingNumber} adsUrl={fullDomainWithPort + "/for-sale/" + featureProducts.id} /> : null}
                      </Card.Body>
                    </Card>
          </Col>
        )}
        {numberOfImages > 3 && (
        <Col xs={1} style={{width:"5px",marginLeft:"-30px"}} className="d-flex justify-content-center align-items-center">
          <Button onClick={handleNextClick} disabled={!canNavigate}>&gt;</Button>
        </Col>
        )}
      </Row>
      
    </div>
  );
};

export default ListDetailsImages;
