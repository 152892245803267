import * as React from "react"
import MainHeader from "./Header"
import Body from "./Body"
import Footer from "./Footer"
import "./newHome.css"

const NewHome = () => (
  <div className="flex flex-col">
    <MainHeader active="0" />
    <Body />
    
    <Footer />
  </div>
)

export default NewHome
