import React, { useContext, useEffect } from "react"
import DispatchContext from "./DispatchContext"
import { useImmer } from "use-immer"
import Axios from "axios"
import { useNavigate } from "react-router-dom"
import Post from "./Post"
import "./newHome.css"

//import Post from "./Post"
Axios.defaults.baseURL = process.env.REACT_APP_BACKENDURL || "https://resochi.com"

function Search() {
  const navigate = useNavigate()

  const appDispatch = useContext(DispatchContext)
  const [state, setState] = useImmer({
    searchTerm: "",
    results: [],
    show: "neither",
    requestCount: 0
  })

  useEffect(() => {
    document.addEventListener("keyup", searchKeyPressHandler)
    return () => document.removeEventListener("keyup", searchKeyPressHandler)
  }, [])

  function searchKeyPressHandler(e) {
    if (e.keyCode == 27) {
      appDispatch({ type: "closeSearch" })
      goToSearchResult();
    }
    if (e.keyCode == 13) {
      setState(draft => {
        draft.show = "neither"
      })
      navigate(`/searchResult/${e.target.value}`)
    }
  
  }

  useEffect(() => {
    if (state.searchTerm.trim()) {
      setState(draft => {
        draft.show = "loading"
      })
      const delay = setTimeout(() => {
        setState(draft => {
          draft.requestCount++
        })
      }, 750)
      return () => clearTimeout(delay)
    } else {
      setState(draft => {
        draft.show = "neither"
      })
    }
  }, [state.searchTerm])

  useEffect(() => {
    if (state.requestCount) {
      //send axios request here
      console.log(state.requestCount)
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(`/api/ads/search/${state.searchTerm}`, { cancelToken: ourRequest.token })
          setState(draft => {
            draft.results = response.data
            draft.show = "results"
          })
        } catch (e) {
          console.log("there is issue search")
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  }, [state.requestCount])

  function handleInput(e) {
    const value = e.target.value
    setState(draft => {
      draft.searchTerm = value
    })
  }
  function goToSearchResult() {
    setState(draft => {
      draft.show = "neither"
    })
    state.searchTerm?navigate(`/searchResult/${state.searchTerm}`):navigate("/searchResult")
    
  }

  return (
    <>
      <div id="search" className="search-overlay-top">
        <div className="container container--narrow">
          {/* <label htmlFor="live-search-field" className="search-overlay-icon">
            <i className="fas fa-search"></i>
          </label> */}
          <input style={{ fontSize: "15px", width: "600px",backgroundColor:"white",height:"40px", borderRadius:"20px", paddingLeft:"20px" }} onChange={handleInput} type="text" autoComplete="off" id="live-search-field" className="live-search-field" placeholder="What are you looking for?" />
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" onClick={goToSearchResult}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
          </svg>
          <span onClick={() => appDispatch({ type: "closeSearch" })} className="close-live-search">
            <i className="fas fa-times-circle"></i>
          </span>
        </div>
      </div>

      <div className="search-overlay-bottom">
        <div className="container container--narrow py-3">
          <div className={"circle-loader " + (state.show == "loading" ? "circle-loader--visible" : "")}></div>
          <div className={"live-search-results " + (state.show == "results" ? "live-search-results--visible" : "")}>
            {Boolean(state.results.length) && (
              <div className="list-group shadow-sm">
                {/* <div className="list-group-item active">
                  <strong>Search Results</strong> ({state.results.length} {state.results.length > 1 ? "items" : "item"} found)
                </div> */}
                {state.results.map(post => {
                  return <Post post={post}  onClick={() => {
                    
                    appDispatch({ type: "closeSearch" })
                  }} />
                })}
              </div>
            )}
            {!Boolean(state.results.length) && <p className="alert alert-danger text-center shadow-sm"></p>}
          </div>
        </div>
      </div>
    </>
  )
}

export default Search
