import React, { useEffect } from "react"
import {  useNavigate } from "react-router-dom"

function Post(props: any) {
  const post = props.post
  const date = new Date(post.createdAt)
  const dateFormatted = `${date.getMonth() + 1}/${date.getDay()}/${date.getFullYear()}`
  return (
    <div className="list-group-item list-group-item-action" style={{ cursor: "pointer" }}>
      {/* <Link  href={`/${post.id}`} > */}

      <a href={`/for-sale/${props.post.id}`} >
        {/* <img className="avatar-tiny" src={post.image} /> */}
        <strong>{post.title + " Listing#:" + post.listingNumber}</strong>
        {/* <span className="text-muted small">on {dateFormatted} </span> */}
      </a>
    </div>
  )
}

export default Post
