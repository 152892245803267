import React, { useState } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useTranslation, Trans } from "react-i18next";
import RequestForm from "../raiseRequest/requestForm"
import {  useNavigate } from "react-router-dom"


const YouMayAlsoLikeList = ({ featureProducts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const [seen, setSeen] = useState(false)
  const fullDomainWithPort = window.location.protocol + "//" + window.location.host
  const defaultImg = "https://res.cloudinary.com/dmcaw0vsd/image/upload/v1710189142/photo-comming_e9f54l.png"

  const [currentListingNumber,setCurrentListingNumber] = useState()
  const [itemUrl,setItemUrl] = useState()

  const [data, setData] = useState()

  const navigate = useNavigate()
  function togglePop() {
    setSeen(!seen)
  }
  // Check if there are enough items to navigate
  const canNavigate = featureProducts?featureProducts.length > 3:false;

  const handlePrevClick = () => {
    if (canNavigate) {
      // Go to the previous set of cards or loop to the end if at the beginning
      setCurrentIndex((prevIndex) => (prevIndex - 3 + featureProducts.length) % featureProducts.length);
    }
  };

  const handleNextClick = () => {
    if (canNavigate) {
      // Go to the next set of cards or loop back to the start
      setCurrentIndex((prevIndex) => (prevIndex + 3) % featureProducts.length);
    }
  };
  const goToDetails =(listId)=>{
    window.open(`/for-sale/${listId}`, "_blank")
    //navigate(`/ads/${listId}`)
  }

  const togglePopForListNumberAndId = (listingNumber,itemId) => {
    setCurrentListingNumber(listingNumber); // Update the currentListingNumber state
    setItemUrl(fullDomainWithPort.concat("/for-sale/"+ itemId))
    setSeen(!seen); // Assuming 'seen' is your state to control popup visibility
  };

  // Axios.defaults.baseURL = process.env.REACT_APP_BACKENDURL || "http://45.77.216.104:8081/"

  // useEffect(() => {
  //   // if () {
  //   //send axios request here
  //   //console.log(state.requestCount)
  //   const ourRequest = Axios.CancelToken.source()
  //   async function fetchResults() {
  //     try {
  //       const response = await Axios.get(`/api/ads`)
  //       // setState(draft => {
  //       //   draft.results = response.data
  //       //   draft.show = "results"
  //       // })
  //       const temp = response.data.filter(ads => ads.type == 0).filter(ads => !ads.hideFromFrontEnd).filter(ads=>ads.feature===0)
  //       setData(temp)
  //       setNumberOfRecord(temp.length)
  //       const tempFeatureData = response.data.filter(ads => ads.type == 0).filter(ads => !ads.hideFromFrontEnd).filter(ads=>ads.feature===1)
  //       setFeaturedata(tempFeatureData)
  //     } catch (e) {
  //       console.log("there is issue search")
  //     }
  //   }
  //   fetchResults()
  //   // return () => ourRequest.cancel()
  //   //}
  // }, [])

  return (
    <div style={{paddingLeft:"10px"}}>
      <p style={{marginBottom:"20px", fontSize:"1.5rem"}}>{t("You may be also interested in these items")}</p>
      <Row className="mb-4" >
      {featureProducts.length > 3 && (
        <Col xs={1} style={{width:"10px",marginRight:"-30px",zIndex:"1",position:"relative"}} className="d-flex justify-content-center align-items-center">
          <button className='nav-button left' onClick={handlePrevClick} disabled={!canNavigate}>&lt;</button>
        </Col>
      )}
        {/* {featureProducts.slice(currentIndex, currentIndex + 3).map((item) => (
          <Col key={item.id} xs={10} md={4}>
            <Card>
                      <Card.Img onClick={()=>goToDetails(item.id)} variant="top" style={{ height: "350px", objectFit: "cover",cursor:"pointer" }} src={item.image ? item.image.split("$")[0] : defaultImg} />
                      <Card.Body>
                        <Card.Title onClick={()=>goToDetails(item.id)} style={{cursor:"pointer"}}>{item.title}</Card.Title>
                        <Card.Text>{item.description}</Card.Text>
                        <Card.Footer onClick={togglePop} style={{ backgroundColor: "rgb(41 72 121)", color: "white",cursor:"pointer" }}>
                          {t("Contact us for the price")}
                        </Card.Footer>
                        {seen ? <RequestForm toggle={togglePop} listingNumber={item.listingNumber} adsUrl={fullDomainWithPort + "/ads/" + item.id} /> : null}
                      </Card.Body>
                    </Card>
          </Col>
        ))} */}
        
    {featureProducts.slice(currentIndex, currentIndex + 3).map((item) => {
      // Calculate the item index considering the wrap around
      // const itemIndex = (currentIndex + offset) % featureProducts.length;
      // const item = featureProducts[itemIndex];
      
      return (
        <Col key={item.id} xs={10} md={4}>
          <Card>
            <Card.Img 
            alt={`Resochi.com You may also like listing_image ${item.listingNumber}_${item.make?item.make.name:""}`}

            onClick={() => goToDetails(item.id)} variant="top" style={{ height: "350px", objectFit: "cover", cursor: "pointer" }} src={item.image ? item.image.split("$")[0] : defaultImg} />
            <Card.Body>
              <Card.Title onClick={() => goToDetails(item.id)} style={{cursor:"pointer"}}>{item.make?item.make.name:""} / {item.model?item.model.name:""}</Card.Title>
              <Card.Text>{item.description}</Card.Text>
              <Card.Footer onClick={()=>togglePopForListNumberAndId(item.listingNumber,item.id)} style={{ backgroundColor: "rgb(41 72 121)", color: "white", cursor: "pointer",borderRadius: "var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)" }}>
                {t("Contact us for the price")}
              </Card.Footer>
              {seen ? <RequestForm toggle={togglePop} listingNumber={currentListingNumber} adsUrl={itemUrl} /> : null}
            </Card.Body>
          </Card>
        </Col>
      );
    })
  }
        {/* If less than 3 items are shown, add empty columns to maintain layout */}
    {/* {Array(3 - Math.min(3, featureProducts.slice(currentIndex, currentIndex + 3).length)).fill(0).map((_, index) => (
      <Col key={index} xs={10} md={4} ></Col>
    ))} */}
    {featureProducts.length > 3 && (
        <Col xs={1} style={{width:"5px",marginLeft:"-30px",zIndex:"1",position:"relative"}} className="d-flex justify-content-center align-items-center">
          <button className='nav-button right' onClick={handleNextClick}  disabled={!canNavigate}>&gt;</button>
        </Col>
    )}
      </Row>
      <hr style={{marginTop:"60px",fontSize:"2rem"}}/> {/* Divider */}
    </div>
  );
};

export default YouMayAlsoLikeList;
