import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import React, { useState, useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import CompanyList from "../company/CompanyList"
import Makes from "../productMakes/Makes"
import Models from "../model/Models"
import CompanyContactList from "../companyContact/CompanyContactList"
import Select from "react-select"
import countryList from "react-select-country-list"
import AppContext from "../../AppContext"
import Category from "../category/Category"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import FileUpload from "../FileUpload"

function EditCard(props) {
  const params = useParams() //params.adsId
  const [data, setData] = useState()
  const [adsTitle, setAdsTitle] = useState()
  const [country, setCountry] = useState()
  const [price, setPrice] = useState(0)
  const [adsLocation, setadsLocation] = useState()
  const [adsDescription, setadsDescription] = useState()
  const [adsImage, setadsImage] = useState()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [makesOptions, setMakesOptions] = useState()
  const [makesList, setMakesList] = useState()
  const [modelsOptions, setModelsOptions] = useState()
  const [category, setCategory] = useState()
  const [categoryName, setCategoryName] = useState()
  const [contactsOptions, setContactsOptions] = useState()
  const [companyOptions, setCompanyOptions] = useState()
  const [companyList, setCompanyList] = useState()
  const [waferSizeOptions, setWaferSizeOptions] = useState()
  const [selectedWaferSizeValue, setSelectedWaferSizeValue] = useState()
  const [configuration, setConfiguration] = useState()
  const [note, setNote] = useState()
  const [vintage, setVintage] = useState()
  const [serialNumber, setSerialNumber] = useState()
  const [hideFromFrontEnd, setHideFromFrontEnd] = useState()
  const [imagesUrls, setImagesUrls] = useState([])

  function formatCurrency(e) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(e)
  }

  const [value, setValue] = useState("")
  const options = useMemo(() => countryList().getData(), [])
  useEffect(async () => {
    const result = await axios.get(`${AppContext.apiUrl}/api/ads/${params.adsId}`)
    setData(result.data)
    setAdsTitle(result.data.title)
    setadsLocation(result.data.location)
    setadsDescription(result.data.description)
    setImage(result.data.image)
    // setCategory(result.data.category)
    // setCategoryName(result.data.category.name)

    setSelectedMakeValue({ value: result.data.make.id, label: result.data.make.name })
    setSelectedModelValue({ value: result.data.model.id, label: result.data.model.name })
    setSelectedCompanyValue({ value: result.data.company.id, label: result.data.company.name })
    setSelectedCompanyContactValue({ value: result.data.contact.id, label: result.data.contact.contactName })
    setSelectedWaferSizeValue({ value: result.data.waferSize, label: result.data.waferSize })
    setSelectedCategoryValue({ value: result.data.category.id, label: result.data.category.name })
    setConfiguration(result.data.configuration)
    setNote(result.data.note)
    setVintage(result.data.vintage)
    setSerialNumber(result.data.serialNumber)
    //find the country with code
    setValue(options.find(e => e.value === result.data.country))
    setPrice(formatCurrency(result.data.price))
    setHideFromFrontEnd(result.data.hideFromFrontEnd)
    setImagesUrls(result.data.image ? result.data.image.split("$") : null)
  }, [])

  const changeHandler = value => {
    setValue(value)
  }
  const token = JSON.parse(localStorage.getItem("token"))

  let file = false
  const [selectedCategoryValue, setSelectedCategoryValue] = useState("")

  const handleSelectCategoryChange = value => {
    setSelectedCategoryValue(value)
    console.log("category is ", value)
  }

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const companyId = 5
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const title = e => {
    setAdsTitle(e.target.value)
  }

  const location = e => {
    setadsLocation(e.target.value)
  }

  const description = e => {
    setadsDescription(e.target.value)
  }

  const uploadImage = async e => {
    const files = e.target.files
    const data = new FormData()
    data.append("file", files[0])
    data.append("upload_preset", "adsimage")
    setLoading(true)

    const res = await axios.post("https://api.cloudinary.com/v1_1/elmelm/image/upload", data).then(res => {
      file = true
      setImage(res.data.secure_url)
      setLoading(false)
    })
  }
  useEffect(() => {
    console.log("-----imagesUrls when editing ads---------------->>>")
    console.log(imagesUrls)
    //update image field
    //setImage(imagesUrls.join("$"))
  }, [imagesUrls])

  const postAds = e => {
    e.preventDefault()
    const data = {
      title: adsTitle,
      country: value ? value.value : "",
      description: adsDescription,
      image: imagesUrls.join("$"),
      location: adsLocation,
      user: {
        id: id
      },

      company: {
        id: selectedCompanyValue.value
      },
      contact: {
        id: selectedCompanyContactValue.value
      },
      make: {
        id: selectedMakeValue.value
      },
      model: {
        id: selectedModelValue.value
      },
      category: {
        id: selectedCategoryValue ? selectedCategoryValue.value : 0
      },
      price: price.replace("$", "").replace(",", ""),
      status: "ACTIVE",
      waferSize: selectedWaferSizeValue.value,
      configuration: configuration,
      note: note,
      vintage: vintage,
      serialNumber: serialNumber,
      hideFromFrontEnd: hideFromFrontEnd
    }

    axios
      .put(`${AppContext.apiUrl}/api/ads/${params.adsId}`, data, config)
      .then(response => {
        console.log(response.data)
        navigate("/editAds")
      })
      .catch(error => {
        console.log(error)
        navigate("/login")
      })
  }
  const stateValue = { value: "Ion Implanters", label: "Ion Implanters" }

  const [selectedMakeValue, setSelectedMakeValue] = useState("")

  const handleSelectMakeChange = value => {
    setSelectedMakeValue(value)
    console.log("make is ", value)
  }

  const [selectedModelValue, setSelectedModelValue] = useState("")

  const handleSelectModelChange = value => {
    setSelectedModelValue(value)
    console.log("model is ", value)
  }

  const [selectedCompanyValue, setSelectedCompanyValue] = useState("")

  const handleSelectCompanyChange = value => {
    setSelectedCompanyValue(value)
    console.log("company is ", value)
  }

  const [selectedCompanyContactValue, setSelectedCompanyContactValue] = useState("")

  const handleSelectCompanyContactChange = value => {
    setSelectedCompanyContactValue(value)
    console.log("contact is ", value)
  }

  useEffect(async () => {
    let result = await axios(`${AppContext.apiUrl}/api/makes`)

    setMakesList(result.data)

    const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
    setMakesOptions(category)

    //set warfsize
    setWaferSizeOptions([
      { value: '4"', label: '4"' },
      { value: '5"', label: '5"' },
      { value: '6"', label: '6"' },
      { value: '8"', label: '8"' },
      { value: '12"', label: '12"' }
    ])
  }, [])

  useEffect(() => {
    if (selectedMakeValue & makesList) {
      makesList.filter(makes => {
        if (makes.id === selectedMakeValue.value) {
          const modelsList = makes.model.map(cat => ({ value: cat.id, label: cat.name }))
          setModelsOptions(modelsList)
        }
      })
    }
  }, [selectedMakeValue])

  useEffect(async () => {
    if (selectedModelValue && selectedModelValue.value !== 0) {
      let result = await axios(`${AppContext.apiUrl}/api/category/byMakeModel/${selectedMakeValue.value}/${selectedModelValue.value}`)

      //const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
      //console.log("category " + category)
      setSelectedCategoryValue({ value: result.data.id, label: result.data.name })
    }
  }, [selectedModelValue])

  useEffect(async () => {
    let result = await axios(`${AppContext.apiUrl}/api/company`)
    setCompanyList(result.data)
    const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
    //console.log("category " + category)
    setCompanyOptions(category)
  }, [])

  useEffect(() => {
    if (selectedCompanyValue & companyList) {
      companyList.filter(company => {
        if (company.id === selectedCompanyValue.value) {
          const contactList = company.contact.map(cat => ({ value: cat.id, label: cat.contactName }))
          setContactsOptions(contactList)
        }
      })
    }
  }, [selectedCompanyValue])

  return (
    <div>
      <Navbar />
      {data && (
        <form>
          <div className="ads-from">
            <div>
              <label>Ads title</label>
            </div>
            <input type="text" defaultValue={adsTitle} autoFocus onChange={title} name="title" id="title_id" />
            <div>
              <label>Hide from front end?</label>
            </div>
            <div>
              <div>
                Yes <input type="radio" name="hideFromFrontEnd" value="true" onChange={e => setHideFromFrontEnd(true)} checked={hideFromFrontEnd === true} />
              </div>
              <div>
                No <input type="radio" name="hideFromFrontEnd" value="false" onChange={e => setHideFromFrontEnd(false)} checked={hideFromFrontEnd === false} />
              </div>
            </div>
            <div>
              <label>Makes:</label>
            </div>
            <div>
              <Makes options={makesOptions} selectedValue={selectedMakeValue} onSelectChange={handleSelectMakeChange} />
            </div>
            <div>
              <label>Model:</label>
            </div>
            {/* stateValue={{ value: "PI 9500", label: "PI 9500" }}  */}
            <div>
              <Models options={modelsOptions} selectedValue={selectedModelValue} onSelectChange={handleSelectModelChange} />
            </div>
            <div>
              <label>Category:</label>
            </div>
            <Category selectedValue={selectedCategoryValue} onSelectChange={handleSelectCategoryChange} />
            <div>
              <label>Description</label>
            </div>
            <textarea rows="4" value={adsDescription} cols="50" placeholder="" name="descritpion" id="des_id" onChange={description} />
            {/* <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
              Category
            </button>
            <ul className="dropdown-menu">
              <button className="dropdown-item" type="button" onClick={categoryRealEstate}>
                backend
              </button>
              <button className="dropdown-item" type="button" onClick={categoryCar}>
                frontend
              </button>
              <button className="dropdown-item" type="button" onClick={categoryDevices}>
                devices
              </button>
            </ul>
            <h5 id="categoryTitle"></h5>
          </div> */}
            <div>
              <label>Company:</label>
            </div>
            <div>
              <CompanyList options={companyOptions} selectedValue={selectedCompanyValue} onSelectChange={handleSelectCompanyChange} />
            </div>
            <div>
              <label>Company contact person:</label>
            </div>
            <div>
              <CompanyContactList options={contactsOptions} selectedValue={selectedCompanyContactValue} onSelectChange={handleSelectCompanyContactChange} />
            </div>
            <div>
              <label>Wafer Size:</label>
            </div>
            <Select options={waferSizeOptions} value={selectedWaferSizeValue} onChange={e => setSelectedWaferSizeValue(e)} />
            <div>
              <label>Configuration</label>
            </div>
            <input type="text" value={configuration} name="configuration" id="configuration_id" placeholder="" onChange={e => setConfiguration(e.target.value)} />
            <div>
              <label>note</label>
            </div>
            <input type="text" value={note} name="note" id="note_id" placeholder="" onChange={e => setNote(e.target.value)} />
            <div>
              <label>vintage</label>
            </div>
            <input type="text" value={vintage} name="vintage" id="vintage_id" placeholder="Put only numbers" onChange={e => setVintage(e.target.value)} />
            <div>
              <label>serial number</label>
            </div>
            <input type="text" value={serialNumber} name="serialNumber" id="serialNumber_id" placeholder="" onChange={e => setSerialNumber(e.target.value)} />

            <div>
              <label>country</label>
            </div>
            <Select options={options} value={value} onChange={changeHandler} />

            <div>
              <label>price:</label>
            </div>
            <input type="text" value={price} name="price" id="price_id" placeholder="" onChange={e => setPrice(e.target.value)} />

            <label className="image-upload">Upload image</label>
            {/* <input type="file" className="form-control-file image-upload" onChange={uploadImage} /> */}
            <div>
              {/* {data.image && (
                <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                  {data.image
                    ? data.image.split("$").map(item => (
                        <ImageListItem key={item}>
                          <img srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`} src={`${item}?w=164&h=164&fit=crop&auto=format`} loading="lazy" />
                        </ImageListItem>
                      ))
                    : ""}
                </ImageList>
              )} */}
              <FileUpload initialImages={imagesUrls} onImagesChange={setImagesUrls} />
            </div>
            <button type="submit" className="btn btn-primary" onClick={postAds}>
              Submit
            </button>
          </div>
        </form>
      )}
    </div>
  )
}

export default EditCard
