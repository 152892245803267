import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom"
import Home from "./component/home/Home"
import Login from "./component/login/Login"
import Singup from "./component/singup/Singup"
import EditUserInfo from "./component/editUserInfo/EditUserInfo"
import AddAds from "./component/addAds/AddAds"
import Ads from "./component/ads/Ads"
import EditAds from "./component/editAds/EditAds"
import EditCard from "./component/editCard/EditCard"
import NewHome from "./component/newhome/NewHome"
import AppContext from "./AppContext"
import ManageMakes from "./component/manage/ManageMakes"
import ManageModels from "./component/manage/ManageModels"
import ManageCategory from "./component/manage/ManageCategory"
import ManageCompany from "./component/manage/ManageCompany"
import ManageContact from "./component/manage/ManageContact"
import AddPipeline from "./component/pipelines/AddPipeline"
import PipelinesList from "./component/pipelines/PipelinesList"
import SelectForSale from "./component/pipelines/SelectForSale"
import ManagePipeLine from "./component/pipelines/ManagePipeLine"
import SearchResult from "./component/newhome/SearchResult"
import Services from "./component/newhome/Services"
import Contact from "./component/newhome/Contact"
import About from "./component/newhome/About"
import ServicesCE from "./component/newhome/ServicesCE"
import ServicesTEM from "./component/newhome/ServicesTEM"
import ServicesFS from "./component/newhome/ServicesFS"

import ListDetails from "./component/newhome/ListDetails"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGA from 'react-ga4';
import { useEffect } from "react"
import UsedEquipment from "./component/newhome/UsedEquipment"
import SearchMakeModelCat from "./component/newhome/SearchMakeModelCat"
const TRACKING_ID = "G-ZY7TD0F1ZY"; //  Measurement ID

function App() {
  AppContext.apiUrl = "https://resochi.com"
  // AppContext.apiUrl = "http://localhost:8081"
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
}, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NewHome />} />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/singup" element={<Singup />} /> */}
        <Route path="/editUserInfo" element={<EditUserInfo />} />
        {/* <Route path="/addAds" element={<AddAds />} /> */}
        <Route path="/for-sale/:adsId" element={<ListDetails />} />
        <Route path="/searchResult/:keyword" element={<SearchResult />} />
        <Route path="/searchResult" element={<SearchResult />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service/used" element={<SearchResult />} />
        <Route path="/services/circuitedit" element={<ServicesCE />} />
        <Route path="/services/fab_support" element={<ServicesFS />} />
        <Route path="/services/tem" element={<ServicesTEM />} />
        <Route path="/about" element={<About />} />
        <Route path="/usedEquipment" element={<UsedEquipment />} />
        <Route path="/makes/:keyword" element={<SearchResult />} />
        <Route path="/model/:keyword" element={<SearchResult />} />
        <Route path="/category/:keyword" element={<SearchResult />} />
        <Route path="/search" element={<SearchMakeModelCat />} />

      </Routes>
    </BrowserRouter>
  )
}

export default App
