import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import React, { useState, useEffect } from "react"
import axios from "axios"
import "./ads.css"
import { Stack } from "@mui/material"
import RequestForm from "../raiseRequest/requestForm"
import AppContext from "../../AppContext"

import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"
import { Container } from "@mui/material"
import countryList from "react-select-country-list"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import { Helmet } from 'react-helmet';


const Wrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridGap: 8,
  marginTop: 16
})

function Ads(props) {
  const params = useParams()
  const [data, setData] = useState()
  const fullDomainWithPort = window.location.protocol + "//" + window.location.host
  useEffect(async () => {
    const result = await axios(`${AppContext.apiUrl}/api/ads/${params.adsId}`)

    setData(result.data)
  }, [])

  const [seen, setSeen] = useState(false)

  function togglePop() {
    setSeen(!seen)
  }

  return (
    <div>
      <Helmet>
        <title>{data.title}</title>
        <meta name="description" content={data.description} />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <h1>Welcome to Resochi used equipment!</h1>
      <Navbar />
      {/* {data && (
        <div className="ads-card">
          <Stack direction="row" sx={{ gap: 2 }}>
            <div>
              <img className="ads-img" src={data.image} alt="..." />
            </div>
            <div>
              <h1>{data.title}</h1>
              <p className="ads-text"> Listing#: {data.listingNumber}</p>
              <p className="ads-text">Description: {data.description}</p>
              <p className="ads-text">Make: {data.make.name}</p>
              <p className="ads-text">Model: {data.model.name}</p>
              <p className="ads-text">Category: {data.category.name}</p>
              <p className="ads-text">Price: {data.price}</p>
              <p className="ads-text">Vintage: {data.vintage} years</p>
              <p className="ads-text">WaferSize: {data.waferSize}</p>
              <p className="ads-text">Configuration: {data.configuration}</p>
              <p className="ads-text">Country: {data.country}</p>
              <p className="ads-text">
                <small className="text-muted">{data.location}</small>
              </p>
            </div>
          </Stack>
          <div>
            <button className="block" onClick={togglePop}>
              Send Request to get it
            </button>
            {seen ? <RequestForm toggle={togglePop} /> : null}
          </div>
        </div>
      )} */}
      {data && (
        <Container>
          {/* <Grid xs={12} sx={{ border: "1px solid #ccc" }} container>
            <Grid xs={6} sx={{ border: "1px solid #ccc" }} item>
              <div>
                <img className="ads-img" src={data.image} alt="..." />
              </div>
            </Grid>
            <Grid xs={6} sx={{ border: "1px solid #ccc" }} item>
              <div>
                <h1>{data.title}</h1>
                <p className="ads-text"> Listing#: {data.listingNumber}</p>
                <p className="ads-text">Description: {data.description}</p>
                <p className="ads-text">Make: {data.make.name}</p>
                <p className="ads-text">Model: {data.model.name}</p>
                <p className="ads-text">Category: {data.category.name}</p>
                <p className="ads-text">Price: {data.price}</p>
                <p className="ads-text">Vintage: {data.vintage} years</p>
                <p className="ads-text">WaferSize: {data.waferSize}</p>
                <p className="ads-text">Configuration: {data.configuration}</p>
                <p className="ads-text">Country: {data.country}</p>
                <p className="ads-text">
                  <small className="text-muted">{data.location}</small>
                </p>
              </div>
            </Grid>
          </Grid> */}
          <Wrapper>
            <Box>
              <div>
                <h1>{data.make.name + " / " + data.model.name + " " + data.title}</h1>
                <p className="ads-text">Listing#: {data.listingNumber}</p>
                <p className="ads-type">Ads Type: {data.type == 0 ? "For Sale" : "Wanted"}</p>
                <p className="ads-text">Make: {data.make.name}</p>
                <p className="ads-text">Model: {data.model.name}</p>
                <p className="ads-text">Category: {data.category.name}</p>
                <p className="ads-text">Description: {data.description}</p>
                {/* <p className="ads-text">Price: {data.price}</p> */}
                <p className="ads-text">Vintage: {data.vintage} </p>
                <p className="ads-text">WaferSize: {data.waferSize}</p>
                <p className="ads-text">Configuration: {data.configuration}</p>
                {/* <p className="ads-text">Country: {countryList().getLabel(data.country)}</p>
                <p className="ads-text">
                  <small className="text-muted">{data.location}</small>
                </p> */}
              </div>
            </Box>
            <Box>
              {" "}
              {/* <div>
                {" "}
                <img className="ads-img" src={data.image} alt="..." />
              </div> */}
              <div>
                <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                  {data.image
                    ? data.image.split("$").map(item => (
                        <ImageListItem key={item}>
                          <img srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`} src={`${item}?w=164&h=164&fit=crop&auto=format`} loading="lazy" />
                        </ImageListItem>
                      ))
                    : ""}
                </ImageList>
              </div>
            </Box>
          </Wrapper>
          <Wrapper>
            <Box>
              <div>
                <button className="block" onClick={togglePop}>
                  Send Request to get it
                </button>
                {seen ? <RequestForm toggle={togglePop} listingNumber={data.listingNumber} adsUrl={fullDomainWithPort + "/ads/" + params.adsId} /> : null}

                <button className="block" onClick={window.close}>
                  Go back
                </button>
              </div>
            </Box>
          </Wrapper>
        </Container>
      )}
    </div>
  )
}

export default Ads
