import React from "react"
import styled from "styled-components"
import logo from "./rs-logo.png"
import { useNavigate } from "react-router-dom"
import { useTranslation, Trans } from "react-i18next";


const socialIcons = [
  { icon: "", link: "#" },
  { icon: "", link: "#" },
  { icon: "", link: "#" }
]

const quickLinks = [
  { text: "RESOCHI", link: "/about" },
  { text: "BUY EQUIPMENT", link: "/" },
  { text: "Contact", link: "/contact" }
]

function Footer() {
  const navigate = useNavigate()
  function goToBuyEquipment() {
    navigate(`/`)
  }
  function goToContact() {
    navigate(`/contact`)
  }
  const { t, i18n } = useTranslation();

  return (
    <FooterWrapper>
      <FooterContent>
        <FooterGrid>
          <FooterColumn>
            <TrademarkInfo>
              <CompanyLogo onClick={goToBuyEquipment} style={{ cursor: "pointer" }} src={logo} alt="Company Logo" />
              <TrademarkText>{/* Trademark and Recognition: Property of XXX Inc., are the following <br /> trademarks: XX Company , VeraSEM 3D , NanoSEM , VeritySEM , ComPlus , Compass <br />, UVision , Opal. */}</TrademarkText>
            </TrademarkInfo>
          </FooterColumn>
          <FooterColumn>
            <ContactSection>
              <ContactTitle>{t("Quick Links")}</ContactTitle>
              <ContactInfo>
                <ContactItem>
                  <ContactIcon></ContactIcon>
                  <ContactText>
                    <div onClick={goToBuyEquipment} style={{ cursor: "pointer" }}>
                      RESOCHI
                    </div>
                  </ContactText>
                </ContactItem>
                <ContactItem>
                  <ContactIcon></ContactIcon>
                  <ContactText>
                    <div onClick={goToBuyEquipment} style={{ cursor: "pointer" }}>
                    {t("Buy Equipment")}
                    </div>
                  </ContactText>
                </ContactItem>
                <ContactItem>
                  <ContactIcon></ContactIcon>
                  <ContactText>
                    <div onClick={goToContact} style={{ cursor: "pointer" }}>
                    {t("Contact")}
                    </div>
                  </ContactText>
                </ContactItem>
              </ContactInfo>
              {/* <QuickLinksList>
                {quickLinks.map((link, index) => (
                  <QuickLink key={index}>
                    <a href={link.link}>{link.text}</a>
                  </QuickLink>
                ))}
              </QuickLinksList> */}
            </ContactSection>
          </FooterColumn>
          <FooterColumn>
            <ContactSection>
              <ContactTitle>{t("Get In Touch")}</ContactTitle>
              <ContactInfo>
                <ContactItem>
                  <ContactIcon></ContactIcon>
                  <ContactText>{t("Email")}: sales@resochi.com</ContactText>
                </ContactItem>
                <ContactItem>
                  <ContactIcon></ContactIcon>
                  <ContactText>35 PO.Box Otisville NY 10963</ContactText>
                </ContactItem>
              </ContactInfo>
              {/* <SocialTitle>Follow Us ON</SocialTitle>
              <SocialIcons>
                {socialIcons.map((social, index) => (
                  <SocialLink key={index} href={social.link}>
                    <SocialIcon>{social.icon}</SocialIcon>
                  </SocialLink>
                ))}
              </SocialIcons> */}
            </ContactSection>
          </FooterColumn>
          <FooterColumn>{/* <CompanyImage src="" alt="Company" /> */}</FooterColumn>
        </FooterGrid>
        <div style={{ fontSize: "15px", color: "black" }}>Copyright © 2024 RESOCHI LLC All rights reserved.</div>
        <div style={{ marginLeft: "-1010px", marginTop: "20px", fontSize: "10px", color: "black" }}><a href="/privacyPolicy.html" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a> <a href="/cookiesPolicy.html" target="_blank" rel="noopener noreferrer">
      Cookies Policy
      </a></div>
      </FooterContent>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  background-color: var(--gt-3-i-com-nero, #fff);
  display: flex;
  justify-content: center;
`

const FooterContent = styled.div`
  background-color: var(--gt-3-i-com-nero, #fff);
  width: 100%;
  padding: 45px 65px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`

const FooterGrid = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  }
`

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 0;

  @media (max-width: 991px) {
    width: 100%;
  }
`

const TrademarkInfo = styled.div`
  background-color: var(--gt-3-i-com-nero, #fff);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  color: var(--gt-3-i-com-mine-shaft, #2b2b2b);
  font-weight: 400;
  line-height: 17px;
  width: 100%;
  padding: 10px 10px 80px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`

const CompanyLogo = styled.img`
  aspect-ratio: 6.67;
  object-fit: auto;
  object-position: center;
  width: 128px;
  height: 128px;
  align-self: center;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const TrademarkText = styled.p`
  font-family: Roboto, sans-serif;
  margin: 24px 0 37px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const QuickLinksSection = styled.div`
  background-color: var(--gt-3-i-com-nero, #fff);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  color: var(--gt-3-i-com-mine-shaft, #2b2b2b);
  font-weight: 300;
  white-space: nowrap;
  width: 100%;
  padding: 11px 80px 27px 10px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    white-space: initial;
    padding-right: 20px;
  }
`

const QuickLinksTitle = styled.h3`
  color: var(--gt-3-i-com-cloud-burst, #1c244b);
  text-transform: capitalize;
  font: 600 22px/91% Poppins, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
  }
`

const QuickLinksList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const QuickLink = styled.li`
  font-family: Montserrat, sans-serif;
  margin: 35px 0 0 20px;

  @media (max-width: 991px) {
    margin-left: 10px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

const ContactSection = styled.div`
  background-color: var(--gt-3-i-com-nero, #fff);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  color: var(--gt-3-i-com-mine-shaft, #2b2b2b);
  width: 100%;
  padding: 11px 80px 21px 10px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding-right: 20px;
  }
`

const ContactTitle = styled.h3`
  color: var(--gt-3-i-com-cloud-burst, #1c244b);
  text-transform: capitalize;
  font: 600 22px/91% Poppins, sans-serif;
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 29px;
`

const ContactItem = styled.div`
  display: flex;
  align-items: left;
  gap: 9px;
`

const ContactIcon = styled.span`
  font: 900 14px/100% Font Awesome 5 Free, -apple-system, Roboto, Helvetica, sans-serif;
`

const ContactText = styled.p`
  flex-grow: 1;
  font: 400 16px/170% Montserrat, sans-serif;
`

const SocialTitle = styled.h3`
  color: var(--gt-3-i-com-cloud-burst, #1c244b);
  text-transform: capitalize;
  margin-top: 27px;
  font: 600 22px/91% Poppins, sans-serif;
`

const SocialIcons = styled.div`
  display: flex;
  margin-top: 22px;
  padding-right: 80px;
  gap: 5px;
  font-size: 25px;
  color: var(--gt-3-i-com-boston-blue, #398abf);
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  line-height: 100%;

  @media (max-width: 991px) {
    padding-right: 20px;
    white-space: initial;
  }
`

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: var(--gt-3-i-com-nero, #fff);
  width: 50px;
  height: 50px;
  padding: 0 14px;
  color: inherit;
  text-decoration: none;

  @media (max-width: 991px) {
    white-space: initial;
  }
`

const SocialIcon = styled.span`
  font-family: Font Awesome 5 Brands, -apple-system, Roboto, Helvetica, sans-serif;
`

const CompanyImage = styled.img`
  aspect-ratio: 1.54;
  object-fit: auto;
  object-position: center;
  width: 447px;
  max-width: 100%;
  flex-grow: 1;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`

export default Footer
