import React from "react"
import styled from "styled-components"
import logo from "./rs-logo.png"
import { useContext, useState,useEffect } from "react"
import { Link } from "react-router-dom"
import DispatchContext from "./DispatchContext"
import StateContext from "./StateContext"
import Search from "./Search"
import { useNavigate } from "react-router-dom"
import { useTranslation, Trans } from "react-i18next"
import LanguageSelector from "./LanguageSelector"

const Logo = styled.img`
  width: 168px;
  max-width: 100%;
  object-fit: contain;
`

const NavLink = styled.div`
  font-family: Montserrat, sans-serif;
`

const ActiveNavLink = styled(NavLink)`
  color: var(--gt-3-i-com-apple, #355c8b);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -22px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--gt-3-i-com-apple, #355c8b);
  }
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 80px;
  background-color: var(--gt-3-i-com-mercury, #e4e4e4);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
  font-size: 15px;
  color: var(--gt-3-i-com-black, #000001);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 133%;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding: 20px;
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 991px) {
    display: none;  // Default to none on mobile unless menu is open
    flex-basis: 100%;
    justify-content: space-between;
    margin-top: 20px;
    flex-direction: column;  // Stack the links vertically in the mobile menu
  }
`;


const HamburgerIcon = styled.div`
  width: 40px;   // Adjust the width as needed
  height: 30px;  // Adjust the height as needed
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 991px) {
    display: flex;
  }

  div {
    width: 100%;
    height: 4px;  // Adjust thickness of the hamburger lines
    background-color: #000;  // Change color if needed
    border-radius: 2px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform-origin: 1px;
    }

    &:nth-child(2) {
      transform-origin: 1px;
    }

    &:nth-child(3) {
      transform-origin: 1px;
    }
  }
`;
function MainHeader(props) {
  const appDispatch = useContext(DispatchContext)
  const appState = useContext(StateContext)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false);

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }
  function handleSubmit(e) {
    //e.preventDefault()
    appDispatch({ type: "logout" })
    appDispatch({ type: "addFlashMessages", value: "You have successfully logged out" })
  }
  function handleSearchIcon(e) {
    e.preventDefault()
    appDispatch({ type: "openSearch" })
  }

  function goToBuyEquipment() {
    navigate(`/`)
    toggleMenu();
  }
  function goToService() {
    navigate(`/services`)
  }
  function goToContact() {
    navigate(`/contact`)
  }
  function goToAbout() {
    navigate(`/about`)
  }
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  //is mobile for search bar
  const [isMobile, setIsMobile] = useState(window.innerWidth < 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const searchStyle = isMobile ? 
    { height: '40px', marginTop: '0px', width: '100%' } : 
    { height: '0px', marginTop: '-75px' };
  //end of 
  return (
    <Header>
      <HamburgerIcon onClick={toggleMenu}>
        {/* Simple Hamburger Menu Icon */}
        <div></div>
        <div></div>
        <div></div>
      </HamburgerIcon>
      <Logo onClick={goToBuyEquipment}  src={logo} alt="Logo" style={{ height: "60px",cursor: "pointer" }} />
      
      {!menuOpen && (
      <div style={searchStyle}>
        <Search />
      </div>
      )}
      <Nav style={{ display: !isMobile?'flex':menuOpen ? 'flex' : 'none' }}>

        {props.active === "0" ? (
          <ActiveNavLink>
            <div onClick={goToBuyEquipment} style={{ cursor: "pointer" }}>
              {t("Marketplace")}
            </div>
          </ActiveNavLink>
        ) : (
          <NavLink>
            <div onClick={goToBuyEquipment} style={{ cursor: "pointer" }}>
              {t("Marketplace")}
            </div>
          </NavLink>
        )}
        {props.active === "1" ? (
          <ActiveNavLink>
            <div className="dropdown" style={{ position: "relative", display: "inline-block" }}>
              <div onClick={goToService} style={{ cursor: "pointer" }}>
                {t("Service")}
              </div>
              <div className="dropdown-content">
                <Link to="/usedEquipment">{t("Used Equipment")}</Link>
                <Link to="/services/fab_support">{t("Fab Support")}</Link>
                <Link to="/services/circuitedit">{t("Circuit Edit")}</Link>
                <Link to="/services/tem">{t("TEM Sample Preparation")}</Link>
              </div>
            </div>
          </ActiveNavLink>
        ) : (
          <NavLink>
            <div className="dropdown" style={{ position: "relative", display: "inline-block" }}>
              <div onClick={goToService} style={{ cursor: "pointer" }}>
                {t("Service")}
              </div>
              <div className="dropdown-content">
                <Link to="/usedEquipment">{t("Used Equipment")}</Link>
                <Link to="/services/fab_support">{t("Fab Support")}</Link>
                <Link to="/services/circuitedit">{t("Circuit Edit")}</Link>
                <Link to="/services/tem">{t("TEM Sample Preparation")}</Link>
              </div>
            </div>
          </NavLink>
        )}
        {props.active === "2" ? (
          <ActiveNavLink>
            <div onClick={goToAbout}>{t("About")}</div>
          </ActiveNavLink>
        ) : (
          <NavLink>
            <div onClick={goToAbout} style={{ cursor: "pointer" }}>
              {t("About")}
            </div>
          </NavLink>
        )}
        {props.active === "3" ? (
          <ActiveNavLink>
            <div onClick={goToContact}>{t("Contact")}</div>
          </ActiveNavLink>
        ) : (
          <NavLink>
            <div onClick={goToContact} style={{ cursor: "pointer" }}>
              {t("Contact")}
            </div>
          </NavLink>
        )}
        <NavLink><div style={{ fontSize: "8px", width: "70px", minHeight: "20px", height: "20px" }}>
        <LanguageSelector changeLanguage={changeLanguage} />
      </div></NavLink>
      </Nav>

      
      
    </Header>
  )
}

export default MainHeader
