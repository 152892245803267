import { Link, useNavigate } from "react-router-dom"
import "./navbar.css"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../reducers/user/action"

function Navbar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: state.userReducer.token
    }
  })
  const logout = e => {
    const action = removeUser()
    dispatch(action)
    navigate("/")
  }
  const id = state.user.user.id
  const userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }
  const homeLink = id ? "/admin" : "/"
  return (
    <div className="home-header">
      <div className="home">
        <Link to={homeLink}>
          {/* <i class="bi bi-house-door"></i> */}
          <p className="leble-icon-home">Home</p>
        </Link>
      </div>
      {id ? (
        <>
          <div className="add-ads">
            <div class="dropdown">
              <button class="dropbtn">Manage Ads</button>
              <div class="dropdown-content">
                <Link to="/addAds">Create For Sale</Link>
                <Link to="/admin">For Sale</Link>
                <Link to="/addWantedAds">Create Wanted</Link>
                <Link to="/wanted">Wanted</Link>
              </div>
            </div>
          </div>

          <div className="editAds">
            <Link to="/editAds">
              <p className="leble-icon-editAds">Edit Ads</p>
            </Link>
          </div>
          <div className="add-ads">
            <Link to="/admin/addPipeline">
              <p className="leble-icon-addAds">Add Pipeline</p>
            </Link>
          </div>
          <div className="add-ads">
            <Link to="/admin/pipelineList">
              <p className="leble-icon-addAds">Pipelines</p>
            </Link>
          </div>
          <div className="add-ads">
            <div class="dropdown">
              <button class="dropbtn">Manage Data</button>
              <div class="dropdown-content">
                <Link to="/admin/makes">Makes</Link>
                <Link to="/admin/models">Model</Link>
                <Link to="/admin/category">Category</Link>
                <Link to="/admin/company">Company</Link>
                <Link to="/admin/contact">Contact</Link>
              </div>
            </div>
          </div>

          <div className="editAds">
            <Link>
              <p className="leble-icon-editAds" onClick={logout}>
                Logout
              </p>
            </Link>
          </div>
        </>
      ) : (
        <div className="home-login">
          {/* <Link to="/login">
              <i className="bi bi-box-arrow-in-left"></i>
              <p className="leble-icon-login">Login</p>
            </Link> */}
        </div>
      )}
    </div>
  )
}

export default Navbar
