import React, { useState, useEffect } from "react"
import "./requestForm.css"
import axios from "axios"
import AppContext from "../../AppContext"
import { useTranslation, Trans } from "react-i18next";
import Select from 'react-select';

axios.defaults.baseURL = process.env.REACT_APP_BACKENDURL || "https://resochi.com"


function RequestForm(props) {
  {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const { t, i18n } = useTranslation();
    const [serviceType, setServiceType] = useState("")

    const options = [
      { value: 'Buy Equipment', label: 'Buy Equipment' },
      { value: 'Sell Equipment', label: 'Sell Equipment' },
      { value: 'Provide Service', label: 'Provide Service' },
      { value: 'Refurbishment Service Needed', label: 'Refurbishment Service Needed' }
  ];

    function handleSend(e) {
      e.preventDefault()
      // Code to handle login goes here
      const listingNumber = props.listingNumber
      let data = {
        email: email,
        phone: phone,
        listingNumber: "Listing number is "+listingNumber + ", the url is " + props.adsUrl + ", and the message from the guest is " + message,
        firstName: username,
        message: message,
        serviceType:serviceType
      }

      axios.post(`/api/email/sendEmail`, data).catch(error => console.log(error))

      props.toggle()
    }
    const handleChange = (selectedOption) => {
      setServiceType(selectedOption.value);
  };
    return (
      <div className="popup">
        <div className="popup-inner">
          <h5>{t("Please let us know your information and we will get back to you shortly!")}</h5>
          <form onSubmit={handleSend}>
            <label>
              {t("Name")}:
              <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
            </label>
            <label>
            {t("Email")}:
              <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
            </label>
            <label>
            {t("Phone")}:
              <input type="phone" value={phone} onChange={e => setPhone(e.target.value)} />
            </label>
            <div>
              <label style={{width:"90px"}}>Service</label>
              <Select
                options={options}
                onChange={handleChange}
                isSearchable={false}
            />
            </div>
            <label>
            {t("Message")}:
              <input type="text" value={message} onChange={e => setMessage(e.target.value)} />
            </label>
            <button type="submit">{t("Send")}</button>
          </form>

          <button id="x" onClick={props.toggle}>
          {t("Close")}
          </button>
        </div>
      </div>
    )
  }
}

export default RequestForm
