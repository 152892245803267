import * as React from "react"
import MainHeader from "./Header"
import Body from "./Body"
import Footer from "./Footer"
import "./newHome.css"
import SearchBody from "./SearchBody"
import { useParams } from "react-router-dom"
import ListDetailsBody from "./ListDetailsBody"



const ListDetails = () => {
  const params = useParams() 
  return (
  <div className="flex flex-col">
    <MainHeader />
    <ListDetailsBody id={params.adsId}/>
    {/* <HeroImage />
    <RTExpertsSection />
    <SolutionsImage />
    <OurSolutionsSection />

    <SolutionCard />
    <MainActivitiesSection />
    <FooterSection /> */}
    <Footer />
  </div>
)}

export default ListDetails
