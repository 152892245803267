import React, { useState, useEffect } from "react"
//import "./requestForm.css"
import axios from "axios"
import AppContext from "../../AppContext"
import styled from "styled-components"
import { useTranslation, Trans } from "react-i18next";
import Select from 'react-select';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
  
axios.defaults.baseURL = process.env.REACT_APP_BACKENDURL || "https://resochi.com"
const PopupForm = styled.div`
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  width: 40%;
`
function ContactFormConfirmation(props) {
  
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [serviceType, setServiceType] = useState("")
    const { t, i18n } = useTranslation();
    
    

    
    const options = [
      { value: 'Buy Equipment', label: 'Buy Equipment' },
      { value: 'Sell Equipment', label: 'Sell Equipment' },
      { value: 'Provide Service', label: 'Provide Service' },
      { value: 'Refurbishment Service Needed', label: 'Refurbishment Service Needed' }
  ];

  // Function to handle change in selected option
  const handleChange = (selectedOption) => {
      setServiceType(selectedOption.value);
  };
  const [showSnackBar,setShowSnackBar] = useState(false);
  const handleClose = (event) => {
    

    setShowSnackBar(false);
  };
  
    return (
      <>
      
      
        <div >
          <h5 style={{textAlign:"center"}}>{t("Submission Confirmation!")}</h5> </div>
          <p style={{ marginTop: '2em' }}>Thank you for your submission!</p>

          <p style={{ marginTop: '1em' }}>Your form has been successfully submitted. We have received your information and will process it shortly.
</p>
<p style={{ marginTop: '1em' }}>If you have any questions or need further assistance, please contact us at sales@resochi.com.</p>
<p style={{ marginTop: '1em' }}>Reference Number: {props.referenceNumber}
</p>
       
        
      
      </>
    )
  
}

export default ContactFormConfirmation
