import * as React from "react"
import styled from "styled-components"
import back from "./stable-factory.mp4"
import logo from "./rs-logo.png"
import Search from "./Search"
import Listing from "./Listing"
import { useTranslation, Trans } from "react-i18next";
import introVideo from "./stable-factory.mp4"
import FeatureProductList from "./FeatureProductList"
import { useEffect } from "react"
import { Helmet } from "react-helmet"

const MainSection = styled.section`
  display: flex;
  flex-direction: column;
`
const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  width: 100%;
  justify-content: center;
  color: #fff;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const HeroImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`
const HeroContent = styled.div`
  position: relative;
  background: linear-gradient(90deg, rgba(107, 157, 197, 0.8) 0%, rgba(0, 0, 0, 0.2) 92%);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 80px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const HeroText = styled.div`
  display: flex;
  width: 1044px;
  max-width: 100%;
  flex-direction: column;
  margin: 147px 0 190px;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`
const HeroHeading = styled.h1`
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 2px;
  font: 400 58px/75px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    line-height: 58px;
  }
`
const HeroDescription = styled.p`
  margin-top: 19px;
  font: 300 20px/28px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FullWidthImage = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 1024px;
  align-self: center;
  margin-top: 10px;
  max-width: 100%;
`
const FeaturedSection = styled.section`
  justify-content: center;
  align-items: center;
  background-color: #f4f3f3;
  display: flex;
  margin-top: 10px;
  width: 100%;
  padding: 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const FeaturedContent = styled.div`
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  width: 1140px;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FeaturedItem = styled.article`
  padding-bottom: 13px;
  justify-content: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FeaturedItemContent = styled.div`
  background-color: #fff;
  padding: 40px 25px 10px 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const FeaturedItemRow = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`
const FeaturedItemImage = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 27%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`
const FeaturedItemImageElement = styled.img`
  object-fit: auto;
  object-position: center;
  width: 237px;
  max-width: 100%;
  align-self: stretch;
  margin: auto 0;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`
const FeaturedItemText = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 73%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`
const FeaturedItemTextContent = styled.div`
  justify-content: center;
  background-color: #f4f3f3;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 16px;
  color: #3f444b;
  width: 100%;
  padding: 57px 35px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`
const FeaturedItemHeading = styled.h2`
  color: #398abf;
  letter-spacing: 1.5px;
  font: 700 23px/43% Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FeaturedItemDescription = styled.p`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 11px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const FeaturedItemButton = styled.button`
  font-family: Roboto, sans-serif;
  justify-content: center;
  border-radius: 3px;
  border: 2px solid rgba(63, 68, 75, 1);
  align-self: start;
  margin-top: 20px;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  line-height: 100%;
  padding: 17px 27px;
  background: transparent;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`
const FeaturedItemDots = styled.div`
  align-self: center;
  display: flex;
  margin-top: 19px;
  gap: 12px;
`
const FeaturedItemDot = styled.div`
  border-radius: 4px;
  width: 8px;
  height: 8px;
  ${({ active }) => (active ? `background-color: #398abf;` : `background-color: #3a3f45;`)}
`
const SolutionsSection = styled.section`
  background-color: #fff;
  display: flex;
  margin-top: 71px;
  width: 100%;
  flex-direction: column;
  font-size: 50px;
  color: #3a3f45;
  font-weight: 400;
  text-align: center;
  line-height: 120%;
  padding: 4px 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    font-size: 40px;
  }
`
const SolutionsContent = styled.div`
  background-color: #e4e4e4;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    padding: 0 20px;
  }
`
const SolutionsBox = styled.div`
  justify-content: center;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  width: 1140px;
  max-width: 100%;
  flex-direction: column;
  padding: 32px;
  @media (max-width: 991px) {
    font-size: 40px;
    padding: 0 20px;
  }
`
const SolutionsHeading = styled.h2`
  font-family: Montserrat, sans-serif;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 23px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    padding: 0 20px;
  }
`
const SolutionsItem = styled.div`
  background-color: #fff;
  z-index: 10;
  display: flex;
  margin-top: -52px;
  width: 100%;
  flex-direction: column;
  padding: 4px 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const SolutionsItemContent = styled.div`
  background-color: #e4e4e4;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const SolutionsItemBox = styled.div`
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  width: 1140px;
  max-width: 100%;
  height: 414px;
`
const ActivitiesSection = styled.section`
  background-color: #fff;
  z-index: 10;
  display: flex;
  margin-top: -16px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const ActivitiesContent = styled.div`
  background-color: #e4e4e4;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 45px 60px 26px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`
const ActivitiesBox = styled.div`
  padding-top: 44px;
  justify-content: flex-end;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  width: 1140px;
  max-width: 100%;
  flex-direction: column;
`
const ActivitiesHeading = styled.h2`
  color: #000;
  text-align: center;
  align-self: center;
  white-space: nowrap;
  font: 400 50px/120% Montserrat, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
    white-space: initial;
  }
`
const ActivitiesItem = styled.div`
  background-color: #fff;
  display: flex;
  
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const ActivitiesItemContent = styled.div`
  background-color: #fff;
  display: flex;
  padding-bottom: 31px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const ActivitiesItemBox = styled.div`
  background-color: #fff;
  height: 739px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`
const Body = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const previousTitle = document.title;
    const metaDescription = document.createElement('meta');
    const metaKeywords = document.createElement('meta');

    // Set the new metadata
    document.title = "Resochi homepage";
    metaDescription.name = "Used Equipment Global Marketplace";
    metaDescription.content = "Used Equipment Global Marketplace";
    document.head.appendChild(metaDescription);

    metaKeywords.name = "keywords";
    metaKeywords.content = "Used Equipment Global Marketplace";
    document.head.appendChild(metaKeywords);

    // Cleanup function to remove the metadata when the component unmounts
    return () => {
      document.title = previousTitle;
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);
  return (
    <MainSection>
      {" "}

      <HeroSection>
        {" "}<div id="video-background">
    <video autoPlay muted loop>
        <source src={introVideo} type="video/mp4"/>
        Your browser does not support the video tag.
    </video>
    <div className="content">
       <Helmet>
        
        
        <title>title</title>
        <meta name="title" content= "Home page of Resochi, online market place for used equipment" />
        <meta name="description" content="Used Equipment Global Marketplace" />
        <meta name="robots" content="index,follow" />
        
        
      </Helmet>
    
        {/* <HeroImage src={back} alt="Hero background" />{" "} */}
        <HeroContent>
          {" "}
          <HeroText>
            {" "}
            <HeroHeading> {t("AI-Powered Used Equipment Global Marketplace")}</HeroHeading>{" "}
            <HeroDescription>
              {" "}
              <Trans i18nKey="resochi-description">
              {t("\"RESOCHI\" is a coined word derived from combining \"Re\" and \"Sochi.\" The inclusion of \"Re\" <br />suggests various meanings, such as reuse, recycle, refurbish, rental, and resonance. Meanwhile,<br />\"Sochi\" is interpreted as equipment in Japanese.")}</Trans>
            </HeroDescription>{" "}
          </HeroText>{" "}
        </HeroContent>{" "}</div>
</div>
      </HeroSection>{" "}
      {/* <FullWidthImage src="" alt="Full width" />{" "} */}
      <FeaturedSection>
        {" "}
        <FeaturedContent>
          
          <Listing />{" "}
          <FeaturedItem>
            {" "}
            <FeaturedItemContent>
              {" "}
              <FeaturedItemRow>
                {" "}
                <FeaturedItemImage>
                  {" "}
                  <FeaturedItemImageElement src={logo} alt="Featured item" />{" "}
                </FeaturedItemImage>{" "}
                <FeaturedItemText>
                  {" "}
                  <FeaturedItemTextContent>
                    {" "}
                    <FeaturedItemHeading> {t("AI-Powered")} </FeaturedItemHeading> <FeaturedItemDescription>{t("We are building an AI powered Used Equipment Global Marketplace. RESOCHI will be an online platform that utilizes artificial intelligence (AI) technology to facilitate the buying and selling of used equipment on a global scale. Here's how such a platform might function")}</FeaturedItemDescription> <FeaturedItemButton>{t("See more")}</FeaturedItemButton>{" "}
                  </FeaturedItemTextContent>{" "}
                </FeaturedItemText>{" "}
              </FeaturedItemRow>{" "}
            </FeaturedItemContent>{" "}
          </FeaturedItem>{" "}
          {/* <FeaturedItemDots>
            {" "}
            <FeaturedItemDot active /> <FeaturedItemDot /> <FeaturedItemDot active /> <FeaturedItemDot active />{" "}
          </FeaturedItemDots>{" "} */}
        </FeaturedContent>{" "}
      </FeaturedSection>{" "}
      {/* <FullWidthImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/07fbed12dd6657845f5cb3beaf7bf920971669746ba7016b01dae5d2014f1b4e?apiKey=db7637f4f9294efbaf6bd7c21532a927&" alt="Full width" />{" "}
      <SolutionsSection>
        {" "}
        <SolutionsContent>
          {" "}
          <SolutionsBox>
            {" "}
            <SolutionsHeading>OUR SOLUTIONS</SolutionsHeading>{" "}
          </SolutionsBox>{" "}
        </SolutionsContent>{" "}
      </SolutionsSection>{" "}
      <SolutionsItem>
        {" "}
        <SolutionsItemContent>
          {" "}
          <SolutionsItemBox />{" "}
        </SolutionsItemContent>{" "}
      </SolutionsItem>{" "}
      <SolutionsItem>
        {" "}
        <SolutionsItemContent>
          {" "}
          <SolutionsItemBox />{" "}
        </SolutionsItemContent>{" "}
      </SolutionsItem>{" "} */}
      {/* <ActivitiesSection>
        {" "}
        <ActivitiesContent>
          {" "}
          <ActivitiesBox>
            {" "}
            <ActivitiesHeading>Media Center</ActivitiesHeading>{" "}
       <ActivitiesItem>
              {" "}<div class="content"><video  autoplay muted loop id="myVideo" style={{marginLeft:"20px",marginRight:"20px"}} controls>
  <source src={introVideo} type="video/mp4"/>
  
  Your browser does not support the video tag.
</video>  </div>
              <ActivitiesItemContent>
                {/* {" "}<iframe type="text/html" 
    width="640" 
    height="385" 
    src="https://youtu.be/ApcgelCRcZQ"
    frameborder="0">
</iframe> */}
               {/* <ActivitiesItemBox ></ActivitiesItemBox>{" "}
              </ActivitiesItemContent>{" "}
            </ActivitiesItem>{" "}
          </ActivitiesBox>{" "}
        </ActivitiesContent>{" "} 
       </ActivitiesSection>{" "}   */}
    </MainSection>
  )
}
export default Body
