import * as React from "react"
import MainHeader from "./Header"
import Body from "./Body"
import Footer from "./Footer"
import "./newHome.css"
import SearchBody from "./SearchBody"
import ServicesBody from "./ServicesBody"
import ContactBody from "./ContactBody"

const Contact = () => (
  <div className="flex flex-col">
    <MainHeader active="3" />
    <ContactBody />
    {/* <HeroImage />
    <RTExpertsSection />
    <SolutionsImage />
    <OurSolutionsSection />

    <SolutionCard />
    <MainActivitiesSection />
    <FooterSection /> */}
    <div style={{ marginTop: "10px" }}>
      <Footer />
    </div>
  </div>
)

export default Contact
