import * as React from "react"
import MainHeader from "./Header"
import Body from "./Body"
import Footer from "./Footer"
import "./newHome.css"
import SearchBody from "./SearchBody"
import ServicesBody from "./ServicesBody"

const UsedEquipment = () => (
  <div className="flex flex-col">
    <MainHeader active="1" />

    <ServicesBody isService={true} />
    {/* <HeroImage />
    
    <SolutionsImage />
    <OurSolutionsSection />
<RTExpertsSection />
    <SolutionCard />
    <MainActivitiesSection />
    <FooterSection /> */}
    <Footer />
  </div>
)

export default UsedEquipment
