import React, { useState, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import axios from "axios"

const FileUpload = ({ initialImages, onImagesChange }) => {
  const [uploadedImages, setUploadedImages] = useState([])

  useEffect(() => {
    // Set initial images when the component mounts
    setUploadedImages(initialImages || [])
  }, [initialImages])

  const onDrop = async acceptedFiles => {
    // Simulate saving files to the backend and getting URLs
    // const uploadedUrls = await saveToBackend(acceptedFiles)
    const uploadedUrls = await Promise.all(acceptedFiles.map(uploadImage))

    // Update the state with the new URLs
    setUploadedImages([...uploadedImages, ...uploadedUrls])

    // Notify the parent component about the change
    onImagesChange([...uploadedImages, ...uploadedUrls])
  }
  const uploadImage = async file => {
    try {
      const formData = new FormData()
      formData.append("image", file)

      // const response = await fetch("https://api.cloudinary.com/v1_1/elmelm/image/upload", {
      //   method: "POST",
      //   body: formData
      // })
      const data = new FormData()
      data.append("file", file)
      data.append("upload_preset", "adsimage")
      //const res = await axios.post("https://api.cloudinary.com/v1_1/elmelm/image/upload", data)
      const res = await axios.post("https://api.cloudinary.com/v1_1/dmcaw0vsd/image/upload", data)

      if (res.data.secure_url) return res.data.secure_url

      // if (response.ok) {
      //   const data = await response.json()
      //   return data.url // Assuming the API returns an object with a 'url' property
      // } else {
      //   console.error("Image upload failed:", response.statusText)
      //   return null
      // }
    } catch (error) {
      console.error("Error uploading image:", error.message)
      return null
    }
  }
  const onRemove = (index, e) => {
    e.preventDefault()
    const updatedImages = [...uploadedImages]
    updatedImages.splice(index, 1)

    // Update the state and notify the parent component about the change
    setUploadedImages(updatedImages)
    onImagesChange(updatedImages)
  }
  const onAddToFirst = (index, event) => {
    event.preventDefault()
    const updatedImages = [...uploadedImages]
    const movedImage = updatedImages.splice(index, 1)[0]
    updatedImages.unshift(movedImage) // Move the image to the first place
    setUploadedImages(updatedImages)
    onImagesChange(updatedImages)
  }

  const saveToBackend = async files => {
    // Simulate saving files to the backend and getting URLs
    // In a real application, you would make an API call to your backend
    // and retrieve the URLs for the saved images.
    // Here, we'll just use a setTimeout to simulate the asynchronous nature.
    return new Promise(resolve => {
      setTimeout(() => {
        const uploadedUrls = files.map(file => URL.createObjectURL(file))
        resolve(uploadedUrls)
      }, 1000)
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*"
    //maxSize: 20 * 1024 * 1024 // 2 MB limit
  })

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <h5>Drag 'n' drop your images here, or click to select images </h5>
      </div>

      <div>
        <h5>Uploaded Images:</h5>
        <ul>
          {uploadedImages.map((imageUrl, index) => (
            <li key={index} style={liStyles}>
              <img src={imageUrl} alt={`Image ${index}`} style={imageStyles} />
              <div>
                <button style={buttonStyles} onClick={e => onRemove(index, e)}>
                  Remove
                </button>
              </div>
              <div>
                {" "}
                <button style={buttonStyles} onClick={e => onAddToFirst(index, e)}>
                  Set First
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginBottom: "20px"
}

const imageStyles = {
  maxWidth: "100%",
  maxHeight: "100px",
  marginRight: "20px"
}

const liStyles = {
  border: "1px dashed #cccccc"
}

const buttonStyles = {
  marginRight: "20px"
}

export default FileUpload
