import * as React from "react"
import MainHeader from "./Header"
import Body from "./Body"
import Footer from "./Footer"
import "./newHome.css"
import SearchBody from "./SearchBody"
import ServicesBody from "./ServicesBody"


const SkipToContentLink = () => (
  <div className="justify-end pt-4 pl-4 text-base leading-7 whitespace-nowrap bg-white text-zinc-800 w-[77px]">
    Skip <br /> to <br /> content
  </div>
)

const HeroSection = () => (
  <div className="flex overflow-hidden relative flex-col justify-center w-full text-white min-h-[810px] max-md:max-w-full">
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7e19ea98b61f16c4706d002b223ffab4264ce0b9a9945e44b4b192f9ab5824d9?apiKey=db7637f4f9294efbaf6bd7c21532a927&" alt="Hero background image" className="object-cover absolute inset-0 size-full" />
    <div className="flex relative justify-center items-center px-16 py-20 w-full bg-[linear-gradient(90deg,rgba(107,157,197,0.62_0%,rgba(0,0,0,0.89)_92%),#E4E4E4)] max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col mt-36 mb-48 max-w-full w-[1044px] max-md:my-10">
        <h1 className="text-6xl leading-[75px] tracking-[2px] max-md:max-w-full max-md:text-4xl max-md:leading-[58px]">
          
        </h1>
        <p className="mt-5 text-xl font-light leading-7 max-md:max-w-full">
        </p>
      </div>
    </div>
  </div>
)

const HeroImage = () => <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ba3318ff1d66542a3a63b44167b2c6ef2bc220613241cf9010e83499184f61b3?apiKey=db7637f4f9294efbaf6bd7c21532a927&" alt="Hero image" className="self-center mt-2.5 max-w-full aspect-square w-[1024px]" />

const RTExpertsSection = () => (
  <div className="flex justify-center items-center px-16 pt-16 pb-5 mt-2.5 w-full bg-zinc-100 max-md:px-5 max-md:max-w-full">
    <div className="flex flex-col justify-center max-w-full shadow-md bg-white bg-opacity-0 w-[1140px]">
      <div className="flex flex-col justify-center pb-3.5 bg-white max-md:max-w-full">
        <div className="flex flex-col justify-center bg-white max-md:max-w-full">
          <div className="pt-10 pr-6 pb-2.5 pl-20 bg-white max-md:px-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[27%] max-md:ml-0 max-md:w-full">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b09baf315a2f3a23143db591cc1460a8022b675850ed4efb65e08b845a3d5a4c?apiKey=db7637f4f9294efbaf6bd7c21532a927&" alt="RT Experts image" className="shrink-0 self-stretch my-auto max-w-full aspect-[2.38] w-[237px] max-md:mt-10" />
              </div>
              <div className="flex flex-col ml-5 w-[73%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow justify-center px-9 py-14 w-full text-base bg-white text-zinc-700 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                  <h2 className="text-2xl font-bold tracking-widest leading-loose text-cyan-600 max-md:max-w-full">RT Experts™</h2>
                  <p className="mt-3 tracking-wide leading-6 max-md:max-w-full">
                    Resochi™ specializes in remanufacturing semiconductor MASK <br /> systems, enabling customers to access advanced technology at a more <br /> affordable price point.
                  </p>
                  <button className="justify-center self-start px-7 py-4 mt-5 font-medium text-center whitespace-nowrap rounded border-2 border-solid border-zinc-700 leading-[100%] max-md:px-5">See more</button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-3 self-center mt-5">
            <div className="shrink-0 w-2 h-2 bg-cyan-600 rounded" />
            <div className="shrink-0 w-2 h-2 rounded bg-zinc-700" />
            <div className="shrink-0 w-2 h-2 bg-cyan-600 rounded" />
            <div className="shrink-0 w-2 h-2 bg-cyan-600 rounded" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const SolutionsImage = () => <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/07fbed12dd6657845f5cb3beaf7bf920971669746ba7016b01dae5d2014f1b4e?apiKey=db7637f4f9294efbaf6bd7c21532a927&" alt="Solutions image" className="self-center mt-10 max-w-full shadow-sm aspect-[1.79] w-[1120px]" />

const OurSolutionsSection = () => (
  <div className="flex flex-col pt-1 pb-5 mt-16 w-full text-5xl text-center bg-white leading-[60px] text-zinc-700 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
    <div className="flex justify-center items-center px-16 w-full bg-neutral-200 max-md:px-5 max-md:max-w-full max-md:text-4xl">
      <div className="flex flex-col justify-center p-8 max-w-full bg-white shadow-md w-[1140px] max-md:px-5 max-md:text-4xl">
        <h2 className="justify-center items-center px-16 py-6 bg-white max-md:px-5 max-md:max-w-full max-md:text-4xl">OUR SOLUTIONS</h2>
      </div>
    </div>
  </div>
)

const SolutionCard = () => (
  <div className="flex z-10 flex-col pt-1 pb-5 mt-0 w-full bg-white max-md:max-w-full">
    <div className="flex justify-center items-center px-16 w-full bg-neutral-200 max-md:px-5 max-md:max-w-full">
      <div className="shrink-0 max-w-full bg-white shadow-md h-[414px] w-[1140px]" />
    </div>
  </div>
)

const MainActivitiesSection = () => (
  <div className="flex z-10 flex-col justify-center -mt-4 w-full bg-white max-md:max-w-full">
    <div className="flex justify-center items-center px-16 py-10 w-full bg-neutral-200 max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col justify-end pt-11 max-w-full bg-white shadow-md w-[1140px]">
        <h2 className="self-center text-5xl text-center text-black whitespace-nowrap leading-[60px] max-md:text-4xl">MAIN ACTIVITIES</h2>
        <div className="flex flex-col justify-center mt-8 bg-white max-md:max-w-full">
          <div className="flex flex-col pb-8 bg-white max-md:max-w-full">
            <div className="shrink-0 bg-white h-[739px] max-md:max-w-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
)



const Services = () => (
  <div className="flex flex-col">
    <MainHeader active="1" />

    <ServicesBody isService={true} />
    {/* <HeroImage />
    
    <SolutionsImage />
    <OurSolutionsSection />
<RTExpertsSection />
    <SolutionCard />
    <MainActivitiesSection />
    <FooterSection /> */}
    <Footer />
  </div>
)

export default Services
