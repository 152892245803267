import React, { useState, useCallback, useEffect } from "react"
import Paginations from "./Pagination"
//import data from "./data"
import GridExample from "./GridExample"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Axios from "axios"
import RequestForm from "../raiseRequest/requestForm"
import { useTranslation, Trans } from "react-i18next";
import FeatureProductList from "./FeatureProductList"
import { Navigate, useNavigate } from "react-router-dom"

  

const ResultListing = (props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState()
  const [numberOfRecord, setNumberOfRecord] = useState(0)
  const fullDomainWithPort = window.location.protocol + "//" + window.location.host
  const [seen, setSeen] = useState(false)
  const { t, i18n } = useTranslation();

  const [currentListingNumber,setCurrentListingNumber] = useState()
  const [itemUrl,setItemUrl] = useState()

  const navigate = useNavigate()
  function togglePop() {
    setSeen(!seen)
  }
  Axios.defaults.baseURL = process.env.REACT_APP_BACKENDURL || "https://resochi.com"

  useEffect(()=>{
    setData(props.data)
    setNumberOfRecord(props.data?props.data.length:0)
  },[props.data])
  

  let LIMIT = 12

  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault()
      setCurrentPage(page)
    },
    [setCurrentPage]
  )

  const goToDetails =(listId)=>{
    navigate(`/for-sale/${listId}`)
  }

  const togglePopForListNumberAndId = (listingNumber,itemId) => {
    setCurrentListingNumber(listingNumber); // Update the currentListingNumber state
    setItemUrl(fullDomainWithPort.concat("/ads/"+ itemId))
    setSeen(!seen); // Assuming 'seen' is your state to control popup visibility
  };

  const currentData = data ? data.slice((currentPage - 1) * LIMIT, (currentPage - 1) * LIMIT + LIMIT) : ""
  const defaultImg = "https://res.cloudinary.com/dmcaw0vsd/image/upload/v1710189142/photo-comming_e9f54l.png"
  return (
    <main role="main" className="container">

      
      {/* <h1 style={{backgroundColor:"#ffffff",marginBottom:"20px", marginTop:"60px",fontSize:"2rem"}}>{t("Used Equipment")}</h1> */}
      <div className="current-page">
        <div className="user">
          <div className="user__body">
            <Row xs={1} md={3} className="g-4">
              {currentData &&
                currentData.map(item => (
                  <Col key={item.id} className="col-lg-4 mb-3 d-flex align-items-stretch">
                    <Card>
                      <Card.Img onClick={()=>goToDetails(item.id)}  variant="top" style={{ height: "100%",width:"100%", objectFit: "cover",aspectRatio:"1/1",cursor:"pointer" }} src={item.image ? item.image.split("$")[0] : defaultImg} />
                      <Card.Body>
                        {/* <Card.Title onClick={()=>goToDetails(item.id)} style={{cursor:"pointer",fontSize:"15px",marginBottom:"15px"}}>{item.make?item.make.name:""} / {item.model.name?item.model.name:""}</Card.Title>
                        <Card.Text>{item.description}</Card.Text> */}
                        <Card.Title onClick={()=>goToDetails(item.id)} style={{cursor:"pointer"}}><span style={{ fontSize: "15px" }}>{item.make?item.make.name:""}</span> <br /><b> {item.model?item.model.name:""}</b><br/>
                        <span style={{ fontSize: "15px" }}>{item.category?item.category.name:""}</span>
                        </Card.Title>
                        <Card.Text onClick={()=>goToDetails(item.id)} style={{cursor:"pointer"}}>
                          <b>{item.vintage?("Vintage: "+item.vintage):""}</b>
                          <br/>{item.description}</Card.Text>
                        <Card.Footer onClick={()=>togglePopForListNumberAndId(item.listingNumber,item.id)} style={{ backgroundColor: "rgb(41 72 121)", color: "white",cursor:"pointer",borderRadius: "var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)" }}>
                          {t("Contact us for the price")}
                        </Card.Footer>
                        {seen ? <RequestForm toggle={togglePop} listingNumber={currentListingNumber} adsUrl={itemUrl} /> : null}
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
            {/* <table id="table1">
              <thead>
                <tr>
                  <th>#</th>
                  <th>userId</th>
                  <th width="100%">title</th>
                  <th>status</th>
                </tr>
              </thead>
              {currentData.map(item => {
                return (
                  <tbody>
                    <tr>
                      <th scope="row">{item.id}</th>
                      <th scope="row">{item.userId}</th>
                      <td>{item.title}</td>
                      <td>{item.completed ? "completed" : "not completed"}</td>
                    </tr>
                  </tbody>
                )
              })}
            </table> */}
          </div>
        </div>
        <div className="pagination-wrapper">{data && numberOfRecord > LIMIT && <Paginations totalRecords={numberOfRecord} pageLimit={LIMIT} pageNeighbours={2} onPageChanged={onPageChanged} currentPage={currentPage} />}</div>
      </div>
    </main>
  )
}
export default ResultListing
