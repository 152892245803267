import React, { useState } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useTranslation, Trans } from "react-i18next";
import RequestForm from "../raiseRequest/requestForm"
import {  useNavigate } from "react-router-dom"
import './ImageWithSold.css';


const FeatureProductList = ({ featureProducts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const [seen, setSeen] = useState(false)
  const fullDomainWithPort = window.location.protocol + "//" + window.location.host
  const defaultImg = "https://res.cloudinary.com/dmcaw0vsd/image/upload/v1710189142/photo-comming_e9f54l.png"

  const [currentListingNumber,setCurrentListingNumber] = useState()
  const [itemUrl,setItemUrl] = useState()

  const navigate = useNavigate()
  function togglePop() {
    setSeen(!seen)
  }
  // Check if there are enough items to navigate
  const canNavigate = featureProducts?featureProducts.length > 3:false;

  const handlePrevClick = () => {
    if (canNavigate) {
      // Go to the previous set of cards or loop to the end if at the beginning
      setCurrentIndex((prevIndex) => (prevIndex - 3 + featureProducts.length) % featureProducts.length);
    }
  };

  const handleNextClick = () => {
    if (canNavigate) {
      // Go to the next set of cards or loop back to the start
      setCurrentIndex((prevIndex) => (prevIndex + 3) % featureProducts.length);
    }
  };
  const goToDetails =(listId)=>{
    navigate(`/for-sale/${listId}`)
  }

  const togglePopForListNumberAndId = (listingNumber,itemId) => {
    setCurrentListingNumber(listingNumber); // Update the currentListingNumber state
    setItemUrl(fullDomainWithPort.concat("/for-sale/"+ itemId))
    setSeen(!seen); // Assuming 'seen' is your state to control popup visibility
  };

  return (
    <div style={{paddingLeft:"10px"}}>
      <h1 style={{backgroundColor:"#f4f3f3",marginBottom:"20px", fontSize:"2rem"}}>{t("Featured Equipment")}</h1>
      <Row className="mb-4" >
      {featureProducts.length > 3 && (
        <Col xs={1} style={{width:"10px",marginRight:"-30px",zIndex:"1",position:"relative"}} className="d-flex justify-content-center align-items-center">
          <button className='nav-button-feature left' onClick={handlePrevClick} disabled={!canNavigate}>&lt;</button>
        </Col>
      )}
        {/* {featureProducts.slice(currentIndex, currentIndex + 3).map((item) => (
          <Col key={item.id} xs={10} md={4}>
            <Card>
                      <Card.Img onClick={()=>goToDetails(item.id)} variant="top" style={{ height: "350px", objectFit: "cover",cursor:"pointer" }} src={item.image ? item.image.split("$")[0] : defaultImg} />
                      <Card.Body>
                        <Card.Title onClick={()=>goToDetails(item.id)} style={{cursor:"pointer"}}>{item.title}</Card.Title>
                        <Card.Text>{item.description}</Card.Text>
                        <Card.Footer onClick={togglePop} style={{ backgroundColor: "rgb(41 72 121)", color: "white",cursor:"pointer" }}>
                          {t("Contact us for the price")}
                        </Card.Footer>
                        {seen ? <RequestForm toggle={togglePop} listingNumber={item.listingNumber} adsUrl={fullDomainWithPort + "/ads/" + item.id} /> : null}
                      </Card.Body>
                    </Card>
          </Col>
        ))} */}
        {
    // Dynamically calculate and map the items to display
    Array.from({ length: 3 }).map((_, offset) => {
      // Calculate the item index considering the wrap around
      const itemIndex = (currentIndex + offset) % featureProducts.length;
      const item = featureProducts[itemIndex];
      
      return (
        <Col key={item.id} xs={10} md={4}>
          <Card>
            <Card.Img 
            alt={`Resochi.com listing_image ${item.listingNumber}_${item.make?item.make.name:""}`}
             onClick={() => goToDetails(item.id)} variant="top" style={{ height: "350px", objectFit: "cover", cursor: "pointer" }} src={item.image ? item.image.split("$")[0] : defaultImg} />
            {"SOLD"==item.status &&(<div className="overlay-text">SOLD</div>)}
            <Card.Body>
            <Card.Title onClick={()=>goToDetails(item.id)} style={{cursor:"pointer"}}><span style={{ fontSize: "15px" }}>{item.make?item.make.name:""}</span> <br /><b> {item.model?item.model.name:""}</b><br/>
                        <span style={{ fontSize: "15px" }}>{item.category?item.category.name:""}</span>
                        </Card.Title>
                        <Card.Text onClick={()=>goToDetails(item.id)} style={{cursor:"pointer"}}>
                          <b>{item.vintage?("Vintage: "+item.vintage):""}</b>
                          <br/>{item.description}</Card.Text>
              <Card.Footer onClick={()=>togglePopForListNumberAndId(item.listingNumber,item.id)} style={{ backgroundColor: "rgb(41 72 121)", color: "white", cursor: "pointer",borderRadius: "var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius)" }}>
                {t("Contact us for the price")}
              </Card.Footer>
              {seen ? <RequestForm toggle={togglePop} listingNumber={currentListingNumber} adsUrl={itemUrl} /> : null}
            </Card.Body>
          </Card>
        </Col>
      );
    })
  }
        {/* If less than 3 items are shown, add empty columns to maintain layout */}
    {/* {Array(3 - Math.min(3, featureProducts.slice(currentIndex, currentIndex + 3).length)).fill(0).map((_, index) => (
      <Col key={index} xs={10} md={4} ></Col>
    ))} */}
    {featureProducts.length > 3 && (
        <Col xs={1} style={{width:"5px",marginLeft:"-30px",zIndex:"1",position:"relative"}} className="d-flex justify-content-center align-items-center">
          <button onClick={handleNextClick} className='nav-button-feature right' disabled={!canNavigate}>&gt;</button>
        </Col>
    )}
      </Row>
      <hr style={{marginTop:"60px",fontSize:"2rem"}}/> {/* Divider */}
    </div>
  );
};

export default FeatureProductList;
