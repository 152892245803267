import React, { useState, useEffect } from "react"
//import "./requestForm.css"
import axios from "axios"
import AppContext from "../../AppContext"
import styled from "styled-components"
import { useTranslation, Trans } from "react-i18next";
import Select from 'react-select';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ContactFormConfirmation from "./ContactFormConfirmation";
  
axios.defaults.baseURL = process.env.REACT_APP_BACKENDURL || "https://resochi.com"
const PopupForm = styled.div`
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  width: 60%;
`
function ContactForm(props) {
  
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [serviceType, setServiceType] = useState("")
    const { t, i18n } = useTranslation();
    const [referneceNumber,setReferenceNumber] = useState()
    const [validateMessage,setValidateMessage] = useState(false)
    
    function generateRandomReference(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      setReferenceNumber(result)
      return result;
    }
    

    function handleSend(e) {
      e.preventDefault()
      if(!email) {
        setValidateMessage(true)
        return
      }
      // Code to handle login goes here
      const listingNumber = props.listingNumber
      let data = {
        email: email,
        phone: phone,
        listingNumber: "The message from the guest "+firstName+" "+lastName+", email:"+email+", message is " + message +". Reference number is "+generateRandomReference(10),
        serviceType: serviceType,
        firstName: firstName,
        lastName: lastName,
        message: message
        
      }

      console.log(data)

      axios.post(`/api/email/sendEmail`, data).then(
        setShowSnackBar(true)
        //navigate to confirmation
        
      ).catch(error => console.log(error))

      //props.toggle()
    }

    const options = [
      { value: 'Buy Equipment', label: 'Buy Equipment' },
      { value: 'Sell Equipment', label: 'Sell Equipment' },
      { value: 'Provide Service', label: 'Provide Service' },
      { value: 'Refurbishment Service Needed', label: 'Refurbishment Service Needed' }
  ];

  // Function to handle change in selected option
  const handleChange = (selectedOption) => {
      setServiceType(selectedOption.value);
  };
  const [showSnackBar,setShowSnackBar] = useState(false);
  const handleClose = (event) => {
    

    setShowSnackBar(false);
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
    return (
      <>
      
      <PopupForm>
        {!showSnackBar && (
        <div>
          <h5>{t("Please let us know your information and we will get back to you shortly!")}</h5>
          <form onSubmit={handleSend}>
            <div>
              <label style={{ width: "150px" }}>{t("firstName")}:</label>
              <input type="text" style={{ backgroundColor: "white" }} value={firstName} onChange={e => setFirstName(e.target.value)} />
            </div>
            <div>
              <label style={{ width: "150px" }}>{t("lastName")}:</label>
              <input type="text" style={{ backgroundColor: "white" }} value={lastName} onChange={e => setLastName(e.target.value)} />
            </div>
            <div>
              <label style={{ width: "150px" }}>{t("Email")}<span style={{color:"red"}}>*</span>:</label>
              <input type="text" style={{ backgroundColor: "white" }} value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div>
              <label style={{width:"150px"}}>{t("serviceType")}</label>
              <Select
                options={options}
                onChange={handleChange}
                isSearchable={false}
            />
            </div>
            <div>
              <label style={{ width: "150px" }}>{t("Phone")}:</label>
              <input type="text" style={{ backgroundColor: "white" }} value={phone} onChange={e => setPhone(e.target.value)} />
            </div>
            <div>
              <label style={{ width: "150px" }}>{t("Message")}:</label>
              <textarea  style={{ backgroundColor: "white",width:"90%",marginTop:"10px" }} value={message} onChange={e => setMessage(e.target.value)} />
            </div>
            <div>
              <button type="submit">{t("Send")}</button>
            </div>
          </form>
        </div>
        )}
        {showSnackBar && <ContactFormConfirmation referenceNumber={referneceNumber}/>}
        {validateMessage && <Snackbar onClose={()=>{setValidateMessage(false)}} autoHideDuration={3} open={true} color="red" message="Email is required."/>}
      </PopupForm>
      
      </>
    )
  
}

export default ContactForm
